@import (reference) "colors.less";

.dicon {
  width: 40px;
  height: 40px;
  vertical-align: middle;

  &.dicon-sm {
    width: 40px;
    height: 40px;
  }

  &.dicon-lg {
    width: 60px;
    height: 60px;
  }

  &.dicon-xl {
    width: 80px;
    height: 80px;
  }
}

.dark-theme {
  .dicon {
    fill: @color-teal !important; //force background color for group icons
  }
}

.light-theme {
  .dicon {
    fill: @color-peach !important; //force background color for group icons
  }
}

.dicon {
  fill: @color-peach; //default background color

  &.dark {
    fill: @color-teal;
  }

  &.light {
    fill: @color-peach;
  }

  &.yellow {
    fill: #FFE600;
  }

  &.red-bright {
    fill: @color-red-bright;
  }

  &.orange {
    fill: #F7600B;
  }
}
