@import (reference) "colors.less";
@import (reference) "screen-sizes.less";

.pagination {
  margin: 40px 0 10px 0;
  text-align: center;

  .page-links {
    // Mobile only (set to `display: block` in media query)
    display: none;
    vertical-align: top;
    @media (min-width: @screen-tablet) {
      display: inline-block;
    }

    > a, > span {
      .text-large;
      vertical-align: top;
      font-weight: 500;
      box-sizing: border-box;
      min-width: 40px;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      margin: 0 2px 3px;
      border-radius: 40px;
      text-align: center;
      color: @color-text-primary;
      transition: background-color 250ms;
    }

    > a {
      &.active {
        background: @color-primary;
        color: @color-white;
        cursor: default;
      }
      &:hover {
        text-decoration: none;
      }
      &:hover:not(.active) {
        background: @color-hover;
        color: @color-blue-dark;
      }
    }
  }

  // Prev/next buttons
  .page-link-prev, .page-link-next {
    font-size: 16px;
    vertical-align: top;
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 40px;
    margin: 0 2px 3px;
    background: @color-white;
    box-shadow: 0 1px 4px rgba(0,0,0,0.35);
    &:hover:not(.disabled) {
      animation: paginationPopUp forwards 250ms;
    }

    &.disabled {
      background-color: @color-light-grayED;
      box-shadow: none;
      pointer-events: none;
      visibility: hidden;
      i {
        color: @color-grayC5;
      }
    }
    &.hide {
      display: none;
    }
  }

  @keyframes paginationPopUp {
    from {box-shadow: 0 1px 4px rgba(0,0,0,0.35); font-size: 16px;}
    to {box-shadow: 0 3px 6px rgba(0,0,0,0.35); font-size: 17px;}
  }
}
