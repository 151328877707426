@import "colors.less";

.layer-flat {
  border-radius: 4px;
  border: 1px solid @color-light-grayED;
  padding: 5px;
}
.layer-raised {
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  padding: 5px;
}
.layer-dropdown {
  border-radius: 4px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23);
  padding: 5px;
}
.layer-overlay {
  border-radius: 4px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 6px 6px 0 rgba(0, 0, 0, 0.26);
  padding: 5px;
}
.layer-modal {
  border-radius: 4px;
  box-shadow: 0 19px 38px 0 rgba(0, 0, 0, 0.3), 0 15px 12px 0 rgba(0, 0, 0, 0.22);
  padding: 5px;
}
