@import (reference) "colors.less";
@import (reference) "screen-sizes.less";

.breadcrumbs {
  font-size: 16px;
  line-height: 24px;
  margin: 20px 0;
  font-family: TTNorms-Medium, Arial, sans-serif;

  .breadcrumb {
    display: inline-block;

    .breadcrumb-link:hover {
      text-decoration: none;
    }

    .breadcrumb-text {
      color: @color-gray-primary;

      &:hover {
        color: @color-teal;
      }
    }

    .breadcrumb-text.last {
      position: relative;
      font-weight: bold;
    }

    .breadcrumb-text.last::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 4px; /* Thickness of the underline */
      background-color: @color-teal; /* Color of the underline */
      border-radius: 2px; /* Rounding of the ends */
      transform: translateY(10px); /* Adjust to position the line below text */
    }
  }

  i.icon-angle-right {
    color: @color-grayC5;
  }
  @media print {
    display: none;
  }

  .breadcrumbs-icon {
    padding-right: 10px;
    display: inline;
    vertical-align: middle;
  }
}

.breadcrumbs.v1 {
  margin: 10px 0;
}
