@import (reference) "colors.less";
@import (reference) "flexbox.less";
@import (reference) "typography.less";

@-webkit-keyframes autofill {
  to {
    background: transparent;
  }
}

/******** Form Elements **********/
.focus {
  border: 1px solid @color-interactive;
  box-shadow: 0 0 2px 2px rgba(86, 114, 196, 0.4);
}
.hover {
  border: 1px solid @color-interactive;
}
.hover-bg {

  background-color: @color-hover;
}
.focus-bg {
  box-shadow: 0 0 2px 2px rgba(0, 196, 201, 0.37);
}
.focus-teal {
  border: 1px solid @color-teal;
  box-shadow: 0 0 0px 4px fade(@color-teal, 40%);
}
.form, .facet {
  .toggle-switch {
    border-radius: 30px;
    height: 50px;
    min-width: 50px;
    background: @color-white;
    border: 1px solid @color-grayC5;
    box-sizing: border-box;
    padding: 0 12px 0 37px;
    display: inline-block;
    position: relative;
    cursor: pointer;
    transition: background-color .3s, padding .3s;

    input[type="checkbox"] {
      display: none;
    }

    .switch {
      background: @color-white;
      border-radius: 30px;
      width: 38px;
      height: 38px;
      box-sizing: border-box;
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.43);
      position: absolute;
      top: 5px;
      left: 2px;
      margin-left: 0;
      transition: left .3s, margin-left .3s;
    }

    .text-checked, .text-unchecked {
      line-height: 50px;
      text-align: center;
      text-transform: uppercase;
      font-size: 17px;
      transition: opacity .3s;
      padding: 0 10px;

      /*&.focus {
        .focus;
      }*/
    }

    .text-unchecked {
      display: block;
      color: @color-dark-gray87;
    }
    .text-checked {
      display: none;
      color: @color-white;
    }

    &.checked {
      background: @color-interactive;
      padding-left: 12px;
      padding-right: 37px;
      //.focus;

      &:hover {
        background-color: @color-blue-dark;
      }

      .switch {
        left: 100%;
        margin-left: -40px;
      }
      .text-checked {
        display: block;
      }
      .text-unchecked {
        display: none;
      }
    }

    /*&:hover {
      .hover;
    }
    &.focus {
      .focus;
    }*/
  }

  .stepper {
    .flex-container;

    .decrement, .increment {
      border: 1px solid @color-grayC5;
      text-align: center;
      color: @color-text-primary;
      padding: 6px;
      line-height: 20px;
      font-size: 18px;
      transition: background-color .2s;
      cursor: pointer;

      &:hover:not(.disabled) {
        .hover;
      }
      &.disabled {
        cursor: default;
        color: @color-light-grayED;
      }
    }

    .decrement {
      border-right-width: 0;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    .increment {
      border-left-width: 0;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }

    input {
      margin: 0;
      border-radius: 0;
      width: 50px;
      text-align: center;

      &::-webkit-inner-spin-button,
      &::-webkit-outer-spin-button {
        display: none;
        -webkit-appearance: none;
        margin: 0;
      }

      -moz-appearance: textfield;
    }
  }
  .custom-dropdown {
    position: relative;
    border: 1px solid @color-grayC5;
    border-radius: 6px;
    min-width: 200px;
    min-height: 50px;
    box-sizing: border-box;
    i.icon-down-dir {
      position: absolute;
      top: 30%;
      right: 10px;
    }
    .label {
      color: @color-dark-gray9F;
      position: absolute;
      top: 25%;
      left: 12px;
      font-size: 17px;
    }
    .display-text {
      padding: 20px 35px 0 12px;
      box-sizing: border-box;
      font-size: 17px;
      line-height: 18px;
      .font-primary-bold;
    }
    input {
      border: 0;
      border-radius: 0px;
      margin-top: 12px;
    }
    ul.list-options {
      opacity: 0;
      transform: scaleY(0);
      height: 0;
      max-height: 0;
      list-style: none;
      position: absolute;
      top: -2px;
      left: 0;
      width: 100%;
      background-color: @color-white;
      box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.19), 0 2px 6px 0 rgba(0, 0, 0, 0.26);
      margin: 0;
      padding: 0;
      z-index: -1;
      pointer-events: none;

      li.option {
        list-style: none;
        padding: 16px 15px;
        &:not(.disabled) {
          &:hover,
          &:focus {
            background-color: @color-hover;
            cursor: pointer;
          }
          &:focus::before {
            content: "\2190";
            font-weight: bold;
            margin: 0 0.5em;
            float: right;
          }
          &.selected {
            background-color: @color-interactive;
            color: @color-white;
          }
        }
        &.disabled {
          color: @color-gray9;
        }
        &.disabled {
          color: @color-grayC5;
          pointer-events: none;
        }
      }
      &.open {
        transform: scaleY(1);
        z-index: 1000;
        opacity: 1;
        pointer-events: auto;
        transition : max-height .4s, opacity .2s linear;
        overflow-y: auto;
        overflow-x: hidden;
        height: auto;
        max-height: 243px;

        &[data-max-rows='2'] {
          max-height: 135px;
        }
        &[data-max-rows='3'] {
          max-height: 189px;
        }
        &[data-max-rows='5'] {
          max-height: 297px;
        }
        &[data-max-rows='6'] {
          max-height: 351px;
        }
      }
    }
    &.not-empty {
      .label {
        top: 0;
        font-size: 14px;
        transition: top .2s, font-size .2s;
      }
    }
    &:hover, &:focus {
      .hover;
    }
  }
}
