@import (reference) "screen-sizes.less";

.content-results {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 1355px) {
    flex-wrap: wrap;
  }
  @media (max-width: (@screen-desktop - 1)) {
    flex-wrap: wrap;
  }
  @media (max-width: (@screen-tablet - 1)) {
    flex-wrap: wrap;
  }

  .white-content-result {
    display: inline-block;
    margin: 20px;
    position: relative;
    width: 286px;
    height: 286px;

    @media (max-width: 999px) {
      margin: 10px auto;
    }

    .front-section {
      box-sizing: border-box;
      border-bottom: 1px solid #c0c0c0;
      font-size: 14px;
      height: 286px;
      left: 0;
      overflow: hidden;
      padding: 0;
      position: absolute;
      right: 0;
      z-index: 2;

      .image {
        background-color: white;
        border: 1px solid #c0c0c0;
        height: 215px;

        img {
          height: 100%;
          object-fit: cover;
          object-position: 50% 0;
          width: 100%;
        }
      }

      .content-title {
        background-color: white;
        border-left: 1px solid #c0c0c0;
        border-right: 1px solid #c0c0c0;
        padding: 13px 13px 0;
        text-align: left;
        height: 200px;

        .content-type {
          color: #888888;
          text-transform: uppercase;
          font-size: 14px;
        }

        .title {
          color: #444444;
          font-weight: 500;
          margin: 0 0 20px;
          font-size: 20px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          height: 25px;
        }

        .description {
          font-size: 16px;
          overflow: hidden;
          height: 55px;
          color: black;
        }

        .download-btn-container {
          text-align: center;
          margin-top: 10px;
        }
      }
    }

    .front-section:hover {
      .image {
        height: 85px;
      }

      .content-title {
        background-color: #f2f2f2;
      }
    }
  }

  .content-result-link {
    &:hover {
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.35);
    }
  }
}

