@import (reference) "colors.less";
@import (reference) "screen-sizes.less";

.btn-color-solid(@color, @hover-background: fadeout(@color, 10%)) {
  background-color: @color;
  color: @color-white;
  border-radius: 57px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.12);

  &:hover:not(.disabled) {
    background-color: @hover-background;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.28);
    text-decoration: none;
  }
  &:active:not(.disabled), &.active:not(.disabled) {
    outline-style: none;
    background-color: @color;
  }
  &.disabled {
    opacity: .6;
    cursor: default;
    pointer-events: none;
    background-color: @color-disabled;
  }
}
.btn-light-solid(@color, @backgroundColor) {
  background-color: @backgroundColor;
  color: @color;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.12);

  &:hover:not(.disabled) {
    background-color: darken(@backgroundColor, 10%);
    border: 1px solid darken(@backgroundColor, 10%);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.28);
  }
}
.btn-color-outline(@initialTextColor, @initialBackground: #fff, @activeTextColor: @color-btn-primary) {
  background-color: @initialBackground;
  color: @initialTextColor;
  border: 1px solid @initialTextColor;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.06), 0 2px 2px 0 rgba(0, 0, 0, 0.12);

  &:hover:not(.disabled) {
    text-decoration: none;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.28);
    border: 1px solid @initialTextColor;
  }
  &:active:not(.disabled), &.active:not(.disabled) {
    color: @activeTextColor;
    border: 1px solid @initialTextColor;
  }
}

/******** Base ***********/
.btn {
  border: 0;
  box-sizing: border-box;
  padding: 11px 24px;
  display: inline-block;
  font-size: 17px;
  border-radius: 30px;
  line-height: 18px;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  text-align: center;
  transition: opacity 250ms, background-color 250ms, color 250ms, border 250ms, box-shadow 250ms;
  outline-style: none;
  .font-primary-bold;

  // Remove space after button icons
  [class^="icon-"]:before, [class*=" icon-"]:before {
    margin: 0;
  }
  &.disabled {
    opacity: .6;
    cursor: default;
    pointer-events: none;
    .btn-color-solid(@color-disabled);
  }

  &.btn-blue, &.btn-primary {
    .btn-color-solid(@color-btn-primary, @color-blue-light);
  }
  &.btn-secondary {
    padding: 10px 24px;
    .btn-color-outline(@color-btn-primary);
    &:hover:not(.disabled) {
      .btn-color-outline(@color-blue-light);
    }
    &.disabled {
      .btn-color-outline(@color-grayC5);
    }
  }
  &.btn-tertiary, &.btn-link {
    background-color: transparent;
    color: @color-interactive;
    &.btn-inline {
        display: inline-block;
        vertical-align: bottom;
    }

    &:hover:not(.disabled) {
      text-decoration: none;
      background-color: fade(@color-interactive, 10%);
      color: @color-interactive;
      box-shadow: none;
    }
    &:active:not(.disabled), &.active:not(.disabled) {
      color: @color-interactive;
    }
    &.disabled {
      background-color: transparent;
      color: @color-grayC5;
    }
  }
  &.btn-magenta, &.btn-premium {
    .btn-color-solid(@color-btn-premium);
  }
  &.btn-teal {
    .btn-color-solid(@color-teal);
  }
  &.btn-white {
    .btn-color-solid(@color-white);
    color: @color-teal;
  }
  &.btn-burnt-orange {
    .btn-color-solid(@color-burnt-orange);
  }
  &.btn-red, &.btn-danger, &.btn-destructive {
    .btn-color-solid(@color-destructive);
    &.btn-outline {
      /*background: transparent;
      border: 1px solid @color-destructive;
      color: @color-destructive;*/
      .btn-color-outline(@color-destructive);

      &:hover:not(.disabled) {
        .btn-color-outline(@color-destructive);
      }
    }

    &:hover:not(.disabled) {
      color: @color-white;
      background-color: @color-destructive;
    }
  }

  &.btn-black-fri {
    .btn-color-outline(@color-magenta);
  }
  /*&.btn-red, &.btn-danger {
    .btn-color-solid(@color-red);
  }
  &.btn-red-bright {
    .btn-color-solid(@color-red-bright);
  }
  &.btn-red-light {
    .btn-color-solid(@color-red-light);
  }
  &.btn-green {
    .btn-color-solid(@color-green);
  }
  &.btn-yellow {
    .btn-color-solid(@color-yellow);
  }
  &.btn-orange-outline, &.btn-primary-outline {
    .btn-color-outline(@color-primary);
  }
  &.btn-blue-outline, &.btn-secondary-outline {
    .btn-color-outline(@color-secondary);
  }
  &.btn-transparent {
    .btn-color-outline(#fff, transparent, @color-teal);
  }
  &.btn-link {
    background-color: transparent;
    color: @color-blue;

    &:hover:not(.disabled) {
      background-color: rgba(0,0,0,.1);
    }
  }
  &.btn-teal {
    .btn-color-solid(@color-teal);
    &.btn-outline {
      .btn-color-outline(@color-teal);
    }
  }*/


  /***** Sizes *****/
  &.btn-sm {
    padding: 6px 15px;
    line-height: 16px;
    font-size: 14px;
  }
  &.btn-lg {
    line-height: 26px;
    font-size: 20px;
    padding: 12px 24px;

    @media (max-width: 375px) {
      line-height: 18px;
      font-size: 17px;
      padding: 11px 24px;

      &.btn-secondary {
        padding: 10px 24px;
      }
    }
  }
  &.btn-block {
    font-size: 17px;
    display: block;
    width: 100%;
    margin: 8px 0;
  }

  @media print {
    display: none !important;
  }

  /***** Icons *****/
  i {
    &.icon-diamond {
      font-size: 14px;
    }
  }
}


/*Todo: Remove - This is used only in first version of dashboard*/
.btn-group {
  margin-top: 15px;
  box-sizing: border-box;

  .btn {
    border: 1px solid @color-teal;
    margin-left: -1px;
    margin-right: -1px;
    border-radius: 0;
  }
  .btn:first-child, .btn.first {
    border-radius: 20px 0 0 20px;
    margin-left: 0;
    margin-right: 0;
  }
  .btn:last-child, .btn.last {
    border-radius: 0 20px 20px 0;
    margin-right: 0;
    margin-left: 0;
  }
}
