@import (reference) "colors.less";
@import (reference) "screen-sizes.less";

.search-filter {
  position: relative;
  padding: 0 20px 0 0;
  float: left;
  box-sizing: border-box;
  width: 185px;

  .mobile-tab {
    position: absolute;
    right: 0;
    top: 150px;
    background: @color-teal;
    color: #fff;
    height: 25px;
    line-height: 25px;
    padding: 0 15px;
    border: 0;
    display: none;
    font-size: 18px;
    cursor: pointer;
    transform: rotate(-90deg);
    transform-origin: top right;

    .caret {
      margin-right: -4px;
    }
  }

  > ul {
    list-style-type: none;
    margin: 0;
    display: block;
    padding: 0;

    ul, li {
      list-style-type: none;
      padding: 0;
      margin: 0;
      display: block;
    }

    > .title {
      text-align: center;
      font-size: 20px;
      color: @color-gray-primary;
      font-weight: bold;
      display: none;

      .clear-link {
        font-size: 14px;
      }
    }

    .search-box {
      input {
        vertical-align:middle;
        border-radius: 4px;
        border: 1px solid @color-light-grayDB;
        padding: 3px 8px;
        width: 100%;
        box-sizing: border-box;
      }
    }

    > li {
      padding: 10px 0;

      .title {
        padding: 0 0 5px;
        margin: 0;
        font-size: 16px;
        color: @color-gray-primary;
        font-weight: bold;
      }

      &:last-child {
        border-bottom: 0;
      }
      &:first-child {
        padding-top: 0;
      }

      &.facet-section {
        border-bottom: 1px solid @color-light-grayDB;
      }

      > ul {
        border-bottom: 1px solid @color-light-grayDB;
      }

      > ul > li {
        // border-width: 1px 1px 0px 1px;
        // border-color: @color-light-grayDB;
        // border-style: solid;

        a, .filter-value {
          position: relative;
          display: block;
          padding: 8px 12px;
          color: @color-gray-primary;
          font-size: 14px;
        }
        .count {
          color: @color-dark-gray87;
          font-size: .8em;
          margin-left: 3px;
          vertical-align: middle;
        }

        &.disabled {
          a, .filter-value {
            color: @color-light-grayDB;
          }
          .count {
            color: @color-light-grayDB;
          }
        }
        &.collapsed {
          overflow: hidden;
          max-height: 0;
          border-width: 0;
          transition: max-height .5s, border-width .5s;
        }

        &.selected.non-unified {
          a, .filter-value {
            &:before {
              content: '';
              position: absolute;
              margin-top: 0px;
              left: 0px;
              width: 5px;
              height: ~"calc(100% - 15px)";
              background: @color-primary;
            }
          }
        }


        &.title {
          border: 0;
        }
      }

      &.show-collapsed {
        > ul > li.collapsed {
          max-height: 100px;
          border-width: 1px 1px 0px 1px;
        }
      }

      .toggle-collapse {
        font-size: 14px;
        margin-top: 4px;
      }

      .caret {
        display: none;
      }

      .icon-cancel, .icon-cancel-circled {
        display: none;
        position: absolute;
        top: 8px;
        right: 3px;
        color: @color-primary;
        font-size: 18px;
        line-height: 18px;
      }

      .subject-icon {
        margin-right: 1px;
        margin-left: -2px;
        opacity: .7;
      }

      li.selected {
        .icon-cancel, .icon-cancel-circled {
          display: inline-block;
        }
      }
    }
  }

  &.left-collapse {
    @media (max-width: (@screen-tablet - 1)) {
      width: 175px;
      left: -175px;
      position: fixed;
      top: 0;
      bottom: 0;
      transition: left .5s;
      padding: 15px 5px 0;
      background: #fff;
      z-index: 500;

      .mobile-tab {
        display: block;
      }

      > ul {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow-y: auto;
        padding: 15px 4px;

        > .title {
          display: block;
        }
      }

      &.open {
        left: 0;
        z-index: 60000005;
      }
    }
  }

  &.top-collapse {
    @media (max-width: (@screen-tablet - 1)) {
      width: 100% !important;
      height: 60px;
      float: none !important;
      max-width: none !important;
      padding: 6px 3px;
      box-sizing: border-box;
      margin-bottom: 25px;
      border-bottom: 1px solid @color-light-grayDB;

      > ul {
        display: flex;

        .title {
          color: @color-grayC5;
          font-style: italic;
          margin: 0;
          padding: 12px;
          background: @color-light-grayED;
          font-size: 14px;
          font-weight: normal;
          cursor: pointer;
        }
        > li {
          display: inline-block;
          flex: 1 0 auto;
          box-sizing: border-box;
          position: relative;
          vertical-align: top;
          border-bottom: 0;

          > ul {
            position: absolute;
            top: 3px;
            left: 3px;
            right: 3px;
            z-index: 5000002;

            > li {
              display: none;

              a {
                background: @color-light-grayED;
                padding: 12px;
              }

              &.selected {
                display: block;

                a {
                  background: @color-light-grayED;
                }

                &:hover a {
                  background: @color-light-grayED;
                }
              }
            }
          }

          .caret {
            display: block;
            position: absolute;
            right: 8px;
            top: 12px;
            cursor: pointer;
            color: @color-primary;
            font-size: 18px;
            z-index: 5000012;
          }

          .icon-cancel, .icon-cancel-circled {
            display: none;
          }

          &.open {
            .icon-cancel, .icon-cancel-circled {
              display: block;
              top: 12px;
            }

            > ul {
              box-shadow: 0 2px 5px rgba(0,0,0,.25);

              > li {
                display: block;
                border-bottom: 1px solid @color-light-grayDB;

                &.selected {
                  a {
                    background: @color-primary;
                  }
                  &:hover {
                    a {
                      background: @color-primary;
                    }
                  }

                }
              }
            }
          }
        }
      }
    }
  }
}
