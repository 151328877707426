@import (reference) "colors.less";
@import (reference) "typography.less";
@import (reference) "forms.less";

.form, .facet {
  text-align: left;

  label {
    display: block;
    font-size: 14px;
    line-height: 22px;
    color: @color-text-primary;
    vertical-align: middle;
    cursor: pointer;

    &.inline {
      display: inline-block;
      margin-right: 10px;
    }

    &.checkbox, &.radio {
      display: inline-block;
      position: relative;

      input[type="checkbox"], input[type="radio"] {
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 5px;
        left: 3px;
      }

      div.checkbox, div.radio {
        display: inline-block;
        vertical-align: -10px;
        margin: 2px 2px 6px 2px;
        position: relative;
        width: 20px;
        height: 20px;
        border: 1px solid @color-grayC5;
        background: #ffffff;
        pointer-events: none;
        box-sizing: border-box;
        transition: 250ms background;

        &:after {
          content: '';
          position: absolute;
          top: -1px;
          left: -1px;
          width: 20px;
          height: 20px;
          border-radius: 20px;
          box-shadow: 0 0 0 10px @color-hover;
          box-sizing: border-box;
          pointer-events: none;
          opacity: 0;
          z-index: -1;
          transition: 250ms opacity;
        }

        &:before {
          content: '';
          position: absolute;
          box-sizing: border-box;
          pointer-events: none;
          opacity: 0;
          transition: 250ms opacity;
        }

        &.checkbox {
          border-radius: 4px;

          &:before {
            top: 2px;
            left: 6px;
            transform: rotate(45deg);
            width: 7px;
            height: 11px;
            border-right: 3px solid #ffffff;
            border-bottom: 3px solid #ffffff;
          }
        }

        &.radio {
          border-radius: 20px;

          &:before {
            top: 5px;
            left: 5px;
            width: 8px;
            height: 8px;
            background: #ffffff;
            border-radius: 20px;
          }
        }
      }

      span {
        font-size: 16px;
        font-weight: 400;
        user-select: none;
        color: @color-gray-primary;
        transition: 250ms color;
      }

      &:hover {
        div.checkbox, div.radio {
          background: @color-hover;

          &:after {
            opacity: 1;
          }
        }

        //span {
        //  color: @color-btn-primary;
        //}
      }

      input:checked {
        & ~ div.checkbox, & ~ div.radio {
          background: @color-btn-primary;

          &:before {
            opacity: 1;
          }
        }

        & ~ span {
          font-weight: 800;
        }
      }
    }

    &.clear-button {
      display: inline-block;
      position: relative;
      min-width: 90px;
      width: 90px;
      height: 60px;
      overflow: hidden;
      transition: 250ms color, 250ms background;
      margin: 5px 10px 5px 0;

      &:last-child {
        margin: 5px 0 5px 0;
      }

      input[type="checkbox"] {
        opacity: 0;
        cursor: pointer;
        position: absolute;
        top: 2px;
        right: 4px;
      }

      .border {
        border: 1px solid @color-grayC5;
        border-radius: 6px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;
        box-sizing: border-box;
        transition: 250ms border, 250ms background;

        .check {
          position: absolute;
          top: 4px;
          right: 9px;
          transform: rotate(45deg);
          width: 7px;
          height: 11px;
          border-right: 3px solid @color-primary;
          border-bottom: 3px solid @color-primary;
          box-sizing: border-box;
          pointer-events: none;
          opacity: 0;
        }
      }

      .text {
        margin-top: 15px;
        width: 100%;
        height: auto;
        text-align: center;
        color: @color-text-primary;
        transition: 250ms color;

        .middle {
          margin-top: 22px;
          font-size: 16px;
        }

        .top {
          font-weight: 800;
          font-size: 20px;
          height: 20px;
        }

        .bottom {
          font-size: 12px;
          height: 20px;
        }
      }

      &:hover {
        .border {
          border: 1px solid @color-btn-primary;
          background: @color-hover;

          .check {
            border-right: 3px solid @color-btn-primary;
            border-bottom: 3px solid @color-btn-primary;
            opacity: 0.5;
          }
        }

        .text {
          color: @color-btn-primary;
        }
      }

      input:checked {
        & ~ .border {
          border: 1px solid @color-primary;

          .check {
            opacity: 1;
            border-right: 3px solid @color-primary;
            border-bottom: 3px solid @color-primary;
          }
        }

        & ~ .text {
          color: @color-primary;
        }
      }
    }

    &.button {
      padding: 8px 12px;
      border-radius: 8px;
      border: 1px solid @color-light-grayED;
      cursor: pointer;
      font-size: 14px;
      display: inline-block;
      margin: 3px 7px 3px 0;

      &.round {
        box-sizing: border-box;
        border-radius: 36px;
        height: 36px;
        width: 36px;
        text-align: center;
        line-height: 20px;
        font-size: 18px;
        margin-right: 4px;
      }

      input[type="checkbox"] {
        cursor: pointer;
      }

      input[type="radio"] {
        display: none;
      }

      &.checked {
        background: @color-teal;
        color: @color-white;
        border-color: @color-teal;
      }
    }

    &.floating {
      border: 1px solid @color-grayC5;
      box-sizing: border-box;
      border-radius: 8px;
      position: relative;
      transition: border-color .2s;
      cursor: default;
      background: @color-white;

      span {
        color: @color-dark-gray9F;
        position: absolute;
        font-size: 16px;
        line-height: 20px;
        top: 50%;
        left: 8px;
        right: 8px;
        margin-top: -10px;
        z-index: 6;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        transition: top .2s, left .2s, font-size .2s, line-height .2s, color .2s, margin-top .2s;
      }

      &.textarea {
        span {
          top: 25px;
        }
      }

      &.not-empty {
        span {
          font-size: 14px;
          line-height: 16px;
          top: 3px;
          left: 8px;
          margin-top: 0;
        }
      }

      &:hover {
        .hover;
      }

      &:focus, &.focus {
        outline: none;
      }

      input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea, select, .fake-input {
        border: 0;
        padding-top: 21px;
        background: transparent;
        z-index: 3;
        position: relative;
        margin: 0;
        outline-style: none;

        &::placeholder {
          visibility: hidden;
        }

        &:-webkit-autofill {
          -webkit-animation-name: autofill;
          -webkit-animation-fill-mode: both;
        }
      }
    }
  }

  .solid-button {
    display: flex;
    flex-wrap: wrap;

    .option {
      text-align: center;
      padding: 5px;
      margin: 10px 5px;
      border: 1px solid @color-dark-gray9F;
      border-radius: 6px;
      width: 127px;

      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */

      &:not(.disabled) {
        cursor: pointer;
      }

      &:hover:not(.disabled) {
        background: rgba(86, 114, 196, 0.1);
      }

      &.selected {
        background-color: @color-dark-teal;
        color: @color-white;

        &:hover:not(.disabled) {
          background: @color-teal;
        }
      }


      &.checked {
        background-color: @color-dark-teal;
        color: @color-white;

        &:hover {
          background: @color-teal;
        }
      }

    }
  }

  .solid-button-standard {

    .solid-button-label {
      display: inline-block;
      padding: .7em 1em;
      margin: 0.7em;
      font-size: 18px;
      cursor: pointer;
      color: #464646;
      border-radius: 6px;
      background: white;
      border: 1px solid #D2D2D2;
      transition: opacity 250ms, background-color 250ms, color 250ms, border 250ms, box-shadow 250ms;
    }

    .solid-button-label:hover {
      background: #d7d7d7;
      color: #252525;
    }
    input[type=radio]:checked + label {
      background: #00B5BA;
      color: #efefef;
      //border: 1px solid white;
    }

    .hidden {
      display: none;
    }
  }

  .clear-button-flex-container {
    display: flex;
    justify-content: space-between;

    label {
      width: auto;
      min-width: auto;
      max-width: none;

      .text {
        padding: 0 20px;
        box-sizing: border-box;
      }
    }
  }

  input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea, select, .fake-input {
    .font-primary-bold;
    background: #fff;
    display: block;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid @color-grayC5;
    border-radius: 6px;
    line-height: 20px;
    font-size: 16px;
    padding: 8px;
    margin-bottom: 4px;
    margin-top: 2px;
    outline-style: none;

    &:hover {
      .hover;
    }

    &:disabled {
      opacity: .8;
      background: @color-white;
      border: 1px solid @color-light-grayED;
    }
  }

  .fake-input {
    cursor: default;
  }

  //input[type="checkbox"], input[type="radio"] {
  //  vertical-align: middle;
  //  display: inline-block;
  //  margin: 2px 2px 2px 2px;
  //  outline-style: none;
  //  cursor: pointer;
  //
  //  &:checked {
  //    //box-shadow: 0 0 0px 10px fade(@color-interactive, 10%);
  //    border-radius: 20px;
  //  }
  //  &:hover {
  //    .hover-bg;
  //    box-shadow: 0 0 0px 10px fade(@color-interactive, 10%);
  //    border-radius: 20px;
  //  }
  //}

  .input-row {
    padding: 0 0 16px;

    .errormsg {
      .font-primary-bold;
      color: @color-red;
      display: none;
      font-size: 14px;
    }

    &.has-error {
      .errormsg {
        display: inline-block;
      }

      label.floating:not(.focus) {
        border-color: @color-error
      }

      input[type="text"], input[type="password"], input[type="date"], input[type="datetime"], input[type="datetime-local"], input[type="month"], input[type="week"], input[type="email"], input[type="number"], input[type="search"], input[type="tel"], input[type="time"], input[type="url"], input[type="color"], textarea, select, .fake-input {
        border-color: @color-error;
      }

      .custom-dropdown {
        border-color: @color-error;
      }
    }
  }

  .help {
    font-size: 13px;
    color: @color-dark-gray87;
    font-style: italic;
  }

  .search-box {
    position: relative;
    padding-right: 36px;
    background: transparent;

    input {
      border-radius: 30px;
      color: @color-text-primary;
      padding: 10px 10px 10px 50px;

      &::placeholder {
        color: @color-dark-gray9F;
      }
    }

    .btn {
      position: absolute;
      top: 12px;
      left: 20px;
      background: transparent;
      border: 0;
      padding: 4px;
      margin: 0;
      color: @color-interactive;
      outline: none;
    }
  }

  .submit {
    margin-top: 15px;
  }
}
