@import (reference) "colors.less";
@import (reference) "typography.less";

.star-rating-page {
  padding: 0 0 6px;
  font-size: 24px;

  @media print {
    display: none !important;
  }

  .stars-page {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    height: 27px;
    overflow: hidden;

    .star-page {
      display: inline-block;
      position: relative;
      margin-right: -4px;
      padding-right: 5px;
      overflow: hidden;
      width: 24px;
      height: 27px;

      .full {
        overflow: hidden;

        i {
          color: @color-btn-primary;
          position: relative;
        }
      }

      i {
        margin: 0 -3px;

        &.bg {
          color: @color-light-grayDB;
          left: 0;
          position: absolute;
        }
      }
    }
  }

  &.interactive {
    .stars-page {
      cursor: pointer;

      &:hover {
        .star-page {
          .full {
            display: none;
          }

          i {
            &.bg {
              color: @color-btn-primary;
            }
          }

          &:hover ~ .star-page {
            i {
              &.bg {
                color: @color-light-grayDB;
                text-shadow: none;
              }
            }
          }
        }
      }
    }
  }

  .rating-info {
    display: inline-block;
    color: @color-dark-gray87;
    font-size: 14px;

    span {

    }
  }
}

.star-rating {
  padding: 0 0 6px;
  font-size: 12px;

  @media print {
    display: none !important;
  }

  .stars {
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    height: 15px;
    overflow: hidden;

    .star {
      display: inline-block;
      position: relative;
      width: 11px;
      height: 15px;
      margin-right: -5px;
      padding-right: 4.5px;
      overflow: hidden;

      .full {
        overflow: hidden;

        i {
          color: @color-btn-primary;
          position: relative;
        }
      }

      i {
        margin: 0 -3px;

        &.bg {
          color: @color-light-grayDB;
          left: 0;
          position: absolute;
        }
      }
    }
  }

  &.interactive {
    .stars {
      cursor: pointer;

      &:hover {
        .star {
          .full {
            display: none;
          }

          i {
            &.bg {
              color: @color-btn-primary;
            }
          }

          &:hover ~ .star {
            i {
              &.bg {
                color: @color-light-grayDB;
                text-shadow: none;
              }
            }
          }
        }
      }
    }
  }

  .rating-info {
    display: inline-block;
    color: @color-dark-gray87;
    vertical-align: -3px;

    span {
      .text-medium;
    }
  }
}
