@import (reference) "colors";
@import (reference) "color-classes.less";
@import (reference) "typography.less";
@import (reference) "variables.less";

nav.reorg-subnav {
  overflow: visible;
  border-bottom: 1px solid rgba(0, 0, 0, .1);

  a {
    color: @color-gray-primary;
    white-space: nowrap;
  }

  .container {
    max-width: 1150px;
    display: flex;

    .grades ul {
      display: inline-block;
      vertical-align: top;
      flex: 1 auto;
      box-sizing: border-box;
    }
  }

  &.unified-subnav {
    box-shadow: none;
    box-sizing: border-box;
    padding: 0 20px;
    z-index: 9001;

    .unified-subnav-container {
      max-width: 1150px;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      position: relative;
      margin: 0 auto !important;

      @media (max-width: (@screen-homepage)) {
        display: none;
      }

      .popular {
        overflow: hidden;
        padding-top: 6px;
        text-align: left;

        &.links {
          &.hide-small {
            a {
              @media (max-width: 480px) {
                display: inline-block;
              }
            }

            .pumpkin-icon {
              vertical-align: middle;
            }
          }
        }

      }
    }
  }

  .line {
    width: 25px;
    height: 2px;
    background-color: @color-gray-primary;
    border-radius: 4px;
    margin-top: 6px;
  }
}

.new-taxonomy {
  body {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  header.reorg {
    background: #fff;
    color: @color-gray-primary;
    box-sizing: content-box;
    height: 55px;
    position: relative;
    padding: 30px 20px 0;
    margin: auto auto 2px;

    @media (max-width: (@screen-homepage)) {
      padding: 0;
      margin: 0;
    }

    .buttons-wrapper {
      display: flex;
      gap: 10px;
    }

    .icon-diamond, .icon-diamond-outline {
      vertical-align: middle;
      color: @color-teal;
      font-size: 12px;

      &.basic, &.anonymous {
        color: @color-magenta;
      }
    }

    .btn-teal {
      background-color: @color-dark-teal;

      &:hover:not(.disabled) {
        background-color: @color-dark-teal;
      }
    }

    .container {
      display: flex;
      line-height: 40px;
      max-width: 1150px;
      margin: auto;

      .search-form-wrapper {
        display: flex;
        width: 100%;
        z-index: 13;
      }

      .search-form-container {
        width: 100%;

        ::placeholder {
          color: #c5c5c5;
        }

        position: relative;
        transition: width 400ms;

        &:not(.active) {
          .tt-menu {
            // Hide dropdown when the container is not active (SubNav can close it)
            display: none !important;
          }
        }

        form {
          position: relative;

          input {
            border-radius: 40px;
            width: 100%;
            border: 1px solid @color-grayC5;
            box-sizing: border-box;
            background: #fff;
            padding-left: 40px;
            line-height: 40px;
            height: 42px;
            font-size: 16px;
            outline: none;

            &:hover, &:active, &:focus {
              border-color: @color-interactive;
            }

            &.top-nav-search {
              display: inline-block;
              padding-right: 40px;
              text-overflow: ellipsis;
            }
          }

          .icon-search {
            position: absolute;
            color: @color-dark-gray87;
            top: 0;
            left: 10px;
          }

          .icon-cancel-circled {
            display: none;
            position: absolute;
            color: @color-interactive;
            top: 0;
            right: 10px;
            font-size: 24px;
          }
        }
      }

      .hamburger {
        display: none;
        right: 20px;
        top: 2px;
        z-index: 1000;
        padding: 0;
      }

      .logo-holder {
        display: flex;
        align-items: flex-end;
        width: 186px;
        font-size: 38px;
        user-select: none;
        margin-right: 10px;
        margin-top: -2px;
        margin-bottom: 12px;
        z-index: 100;
        flex-shrink: 0;

        .icon-edu-logo {
          color: @color-teal;

          &:before {
            margin-left: 0;
          }
        }

        .icon-logo-mark {
          display: none;
        }

        &:hover {
          text-decoration: none;
        }
      }

      .who-we-are {
        position: relative;
        display: none;
      }

      .progress-tracker, .kid-mode-button {
        cursor: pointer;
        padding: 30px 10px;

        a {
          color: @color-gray-primary;
          font-size: 18px;
          text-decoration: none;

          &:hover {
            color: @color-blue-sapphire;
            text-decoration: none;
          }
        }
      }

      .guidance {
        position: relative;
        padding-right: 0;
      }

      .upgrade-link {
        display: none;
      }

      .login-links {
        vertical-align: top;
        flex: 1 auto;
        box-sizing: border-box;
        text-align: right;
        display: none;
        white-space: nowrap;

        .signin, .react-signin-topnav {
          color: @color-text-primary;
          font-family: TTNorms, Arial, sans-serif;

          &:hover {
            text-decoration: none;
            color: @color-interactive;
          }

          @media (max-width: (@screen-homepage)) {
            display: none;
          }
        }

        @media (max-width: (@screen-homepage)) {
          > a {
            font-size: 14px;
          }

          .react-signup, .react-signup-topnav {
            padding: 5px 28px;
          }

          .signin, .react-signin-topnav {
            margin: 0 20px;
            padding: 0;
          }
        }
      }

      .worksheet-generator-button {
        width: 128px;
        height: 42px;
        display: inline-block;
        margin-left: 10px;
        //margin-right: 10px;

        .wsg-icon {
          padding-top: 2px;
          height: 36px;
        }

        @media (max-width: (@screen-homepage)) {
          display: none;
        }
      }

      .brainzy-access {
        width: 137px;
        display: inline-block;
        position: relative;
        padding-right: 15px;
        @media (min-width: @screen-homepage) and (max-width: 480px) {
          scale: 1.1;
          padding-right: 10px;
        }
        @media (max-width: 480px) {
          width: 96px;
          margin-left: 0 !important;
        }

        .brainzy-link {
          cursor: pointer;
          position: relative;

          &.var_0 {
            height: 50px;
            top: -10px;

            .button {
              position: absolute;
              width: 125px;
              height: 35px;
              border: 1px solid #5672c4;
              box-shadow: 0 0 2px 0 rgba(0, 0, 0, .06), 0 2px 2px 0 rgba(0, 0, 0, .12);
              right: 0;
              top: 15px;
              border-radius: 35px;
              transition: 150ms all;
              @media (max-width: 480px) {
                width: 86px;
                top: 9px;
              }
            }

            .button-inner {
              width: 100%;
              height: 55px;
              position: absolute;
              bottom: 0;
            }

            .roly-clip {
              position: absolute;
              left: -1px;
              bottom: 0;
              border-radius: 17px;
              overflow: hidden;
              width: 100px;
              height: 55px;
              -webkit-mask-image: -webkit-radial-gradient(white, black);

              @media (max-width: 480px) {
                display: none;
              }
            }

            .roly {
              width: 47px;
              height: 44px;
              position: absolute;
              bottom: -1px;
              left: -3px;
              transform-origin: -5px 50px;
              transition: 150ms transform;
              @media (max-width: 480px) {
                display: none;
              }
            }

            .kids {
              position: absolute;
              right: 8px;
              top: 18px;
              width: 75px;
              height: 37.5px;
              transform-origin: -20px 100%;
              transition: 150ms transform;

              @media (max-width: 480px) {
                top: 16px;
                right: 6px;
              }
            }
          }

          &.var_1 {
            img {
              position: relative;
              width: 100px;
              height: 34px;
              transition: 150ms all;
              margin-top: 2px;
            }
          }

          &:hover {
            &.var_0 {
              .button {
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .28);
              }

              .roly {
                transform: scale(1.06);
              }

              .kids {
                transform: scale(1.03);
              }
            }

            &.var_1 {
              img {
                filter: drop-shadow(0 3px 1px rgba(0, 0, 0, .15));
                transform: scale(1.04);
              }
            }
          }
        }

        &.mobile {
          margin: -5px 0 -5px 30px;
          transform: scale(1.25);
          display: none;

          .brainzy-link.var_0 {
            left: 5px;
            width: 125px;
            margin: 25px 0 15px 0;
          }

          .brainzy-link.var_1 {
            img {
              top: 2px;
            }
          }

          div {
            padding: 0 !important;
          }
        }

        @media (max-width: (@screen-homepage)) {
          &.mobile {
            display: block;
          }
        }
      }

      .hamburger-brainzy .brainzy-access {
        @media (max-width: (@screen-homepage)) {
          display: flex;
          margin-left: 50px;
        }
      }

      .brainzy-access {
        @media (max-width: @screen-homepage) {
          transform: scale(0.88);
          transform-origin: right;
        }
      }

      .user-menu {
        max-width: 200px;
        z-index: 98;
        cursor: pointer;
        position: relative;

        .num-notifications {
          display: inline-block;
          width: 18px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          background: @color-dark-teal;
          border-radius: 20px;
          color: #fff;
          font-weight: bold;
          font-size: 14px;
          vertical-align: middle;

          &:empty {
            visibility: hidden;
          }
        }

        .tab-text {
          text-align: right;
          position: relative;
          white-space: nowrap;

          .email-avatar {
            margin-bottom: -2px;
            padding-right: 35px;
            line-height: 20px;

            .email {
              color: @color-gray-primary;
              display: inline-block;
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              vertical-align: middle;
              font-weight: bold;
              line-height: 24px;
              font-size: 14px;
            }

            .user-icon {
              position: absolute;
              right: 0;
              top: 7px;
            }

            .num-notifications {
              position: absolute;
              right: -4px;
              top: 2px;
            }
          }

          .premium-label {
            line-height: 14px;
            padding-right: 35px;

            .icon-diamond-outline {
              font-size: unset;
            }

            span {
              display: inline-block;
              background: @color-dark-teal;
              color: #fff;
              padding: 3px 4px 1px;
              font-size: 9px;
              line-height: 12px;
              font-weight: bold;
              letter-spacing: 0.5px;
              border-radius: 2px;
              vertical-align: middle;
              text-transform: uppercase;
            }
          }
        }

        &.active {
          .tab-text {
            .email-avatar {
              .num-notifications {
                display: none;
              }
            }
          }

          .dropdown {
            display: block;
          }
        }
      }

      .notification-modal {
        text-align: left;
        cursor: default;

        .modal-header {
          display: flex;
          margin-bottom: 25px;
          width: 100%;
          justify-content: space-between;

          h3 {
            margin: 0;
          }

          .delete-all-notifications {
            display: none;
            color: @color-blue-sapphire;
            font-weight: 600;
            cursor: pointer;
            font-size: 16px;
          }
        }

        .notifications-list {
          border-bottom: 1px solid @color-light-grayED;

          .notification {
            display: flex;
            justify-content: space-between;
            position: relative;
            text-align: left;
            line-height: 20px;
            border-top: 1px solid @color-light-grayED;
            padding: 10px;
            align-items: center;

            a {
              display: flex;
              align-items: center;
            }

            .notification-delete {
              cursor: pointer;
              font-size: 16px;
              color: @color-dark-gray87;
              margin-left: 40px;
              padding: 8px 7px;
              border-radius: 25px;

              &:hover {
                background-color: rgba(86, 114, 196, 0.1);
              }
            }
          }
        }
      }
    }

    &.no-search {
      .search-form-container form {
        @media (min-width: @screen-homepage) {
          display: none;
        }
      }
    }

    &[data-account-type="Anonymous"] {
      .container {
        .who-we-are {
          display: block;
        }

        .login-links {
          display: block;
        }

        .brainzy-access {
          display: none;
        }
      }
    }

    &[data-account-type="Basic"] {
      .container {
        .upgrade-link {
          display: block;
          white-space: nowrap;
          @media (max-width: 480px) {
            display: none;
            width: 0;
          }
        }

        .user-menu .tab-text .email-avatar .email {
          margin-top: 10px;
        }

        .user-menu {
          .tab-text {

            .premium-label {
              display: none;
            }

            .email-avatar {
              .num-notifications {
                top: -5px;
              }
            }
          }

          .dropdown {
            ul {
              li {
                .icon-diamond, .icon-diamond-outline {
                  display: inline-block;
                  margin-right: 3px;
                }
              }

              li.premium {
                order: 10;
              }

              li.premium.disabled {
                color: silver;
              }

              li.notifications-row {
                border-top: 0;
                padding-top: 5px;
                margin-top: 0;
              }

              li.premium-header {
                order: 8;
                display: block;
                margin-top: 10px;
                border-top: 1px solid rgba(0, 0, 0, .1);
                padding-top: 15px;
                padding-bottom: 10px;

                span {
                  text-transform: uppercase;
                  color: @color-dark-gray87;
                  font-size: 9px;
                  font-weight: bold;
                  line-height: 12px;
                  letter-spacing: 1px;
                  vertical-align: middle;
                }

                i {
                  vertical-align: middle;
                }
              }

              li.upsell {
                order: 15;
                display: block;
                padding-top: 15px;

                a {
                  display: block;
                  font-size: 14px;
                  padding: 6px 16px;
                }
              }

              li.tools-for-teaching {
                order: 16;
                padding-top: 7px;
              }

              li.signout {
                order: 20;
              }
            }
          }
        }
      }
    }

    /*!
     * Hamburgers
     * @description Tasty CSS-animated hamburgers
     * @author Jonathan Suh @jonsuh
     * @site https://jonsuh.com/hamburgers
     * @link https://github.com/jonsuh/hamburgers
    */

    .hamburger {
      top: 2px;
      display: inline-block;
      cursor: pointer;
      transition-property: opacity, filter;
      transition-duration: 0.15s;
      transition-timing-function: linear;
      font: inherit;
      color: inherit;
      text-transform: none;
      background-color: transparent;
      border: 0;
      margin: 0;
      overflow: visible;
      transform: scaleY(0.7);
    }

    .hamburger-box {
      width: 26px;
      height: 24px;
      display: inline-block;
      position: relative;
    }

    .hamburger-inner {
      display: block;
      top: 50%;
      margin-top: 8px;
    }

    .hamburger-inner, .hamburger-inner::after {
      width: 25px;
      height: 3px;
      background-color: @color-gray-primary;
      border-radius: 0;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    .hamburger-inner::after {
      content: "";
      display: block;
    }

    .hamburger-inner::after {
      bottom: -10px;
    }

    .hamburger--slider .hamburger-inner {
      top: 0px;
    }

    .hamburger--slider .third-line {
      top: 10px;
    }

    .hamburger--slider .hamburger-inner::after {
      top: 10px;
    }

    &.expanded {
      .hamburger--slider .hamburger-inner.first-line {
        transform: translate3d(1px, 9px, 0) rotate(45deg);

        &:after {
          display: none;
        }
      }

      .hamburger--slider .hamburger-inner.third-line {
        transform: translate3d(1px, -1px, 0) rotate(-45deg);

        &:after {
          display: none;
        }
      }
    }
  }

  .guided-library-contain {
    display: flex;
    flex-direction: row-reverse;
    @media (max-width: (@screen-homepage)) {
      flex-direction: column;
      flex-shrink: 0;
    }
  }

  @media (max-width: 1199px) {
    header.reorg {
      .container {
        padding: 0;

        .tab {
          .tab-text {
            i {
              margin-left: -2px;
            }
          }

          .dropdown {
            .flex-grid .col {
              padding-right: 8px;

              &.left-side {
                min-width: 60%;
                margin-right: 30px;

                > .flex-grid > .col:first-of-type {
                  flex-basis: 8%;
                }
              }
            }
          }
        }
      }

      &[data-account-type="Anonymous"], &[data-account-type="Basic"] {
        .container {
          .user-menu .tab-text .email-avatar .email {
            margin-top: 10px;
          }

          .search-form-container {
            form {
              input {
                font-size: 16px;
                padding-left: 40px;
              }

              .icon-cancel-circled {
                right: 10px;
              }
            }
          }
        }
      }
    }

    nav.reorg-subnav {
      font-size: 14px;
      padding: 0 25px;

      .container {
        ul {
          li {
            padding: 0 10px;

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1100px) {
    header.reorg {
      .container {
        .search-form-container {
          form {
            input {
              .icon-cancel-circled {
                right: 10px;
              }

              &.top-nav-search {
                display: inline-block;
              }
            }
          }
        }
      }
    }

    nav.reorg-subnav {
      .container {
        ul {
          li {
            padding: 0 6px;
          }
        }
      }
    }
  }

  @media (max-width: (@screen-homepage) ) {
    header.reorg {
      position: relative;
      height: 80px;

      .container {
        margin-left: 0;
        padding-top: 20px;

        .search-form-wrapper {
          margin-left: 15px;
        }

        .hamburger {
          display: flex;
          margin-right: 20px;
          margin-left: 8px;
        }

        &:not([data-account-type="Anonymous"]) {
          // User icon animation
          .hamburger {
            margin-top: 4px;
            top: 10px;
            right: 0;
          }
        }

        .logo-holder {
          z-index: 101 !important;
          width: 40px;

          .icon-edu-logo {
            display: none;
            }

          .icon-logo-mark {
            display: inline-block !important;
            color: @color-teal;
            position: relative;
          }
        }
      }
      &.expanded {
        .container {
          margin-left: 0;
        }
        .logo-holder {
          .icon-edu-logo {
            display: none;
          }

          .icon-logo-mark {
            color: @color-teal;
            visibility: visible;
            transition: visibility 0s linear 0s !important;
          }
        }
        .hamburger {
          margin-top: 20px !important;
          position: fixed !important;
          transform: scaleY(1);
        }
      }
    }
  }

  @media (max-width: (@screen-homepage) ) {
    header.reorg {
      .container {

        .progress-tracker, .kid-mode-button {
          margin-bottom: 5px;
          padding: 0 20px !important;

          a {
            font-size: 20px;
          }
        }

        .upgrade-link {
          a {
            padding: 6px 12px;
            font-size: 14px;
          }
        }

        .brainzy-access {
          scale: 1.1;
          margin-left: 10px;
        }
      }
    }

    nav.reorg-subnav {
      .container {
        .popular {
          margin-right: 0;

          li {
            &.header {
              .extra-text {
                display: inline;
              }
            }
          }
        }

        .grades {
          display: none;
        }
      }
    }
  }

  @media (max-width: 750px) {
    nav.reorg-subnav {
      .container {
        padding: 6px 0;
        max-width: none;
        text-align: left; //center;
        ul {
          display: block;
        }

        .popular {
          li {
            font-size: 14px;
            line-height: 17px;

            &.header {
              display: block;
              font-size: 12px;
              line-height: 18px;
              padding-left: 6px;

              .extra-text {
                display: none;
              }

              .extra-text-small {
                display: inline;
              }
            }

            &.back-to-school-topnav {
              margin-top: -5px;
            }
          }
        }

        .grades {
          display: none;
        }
      }
    }
  }

  .react-signup-topnav {
    max-width: 150px;

    &.mobile {
      display: none;

      @media (max-width: 320px) {
        display: inline-block;
      }
    }

    @media (max-width: 320px) {
      display: none;
    }
  }

  @media (max-width: @screen-phone-lg) {
    header.reorg {
      .container {

        .user-menu {
          &.active {
            background: transparent;
          }

          &:hover {
            background: transparent;
          }

          width: 50px;
          height: 28px;

          .tab-text {
            .email-avatar {
              margin-bottom: 0px;

              .email {
                display: none;
              }
            }

            .premium-label {
              display: none;
            }
          }

          .dropdown {
            // Magic numbers :)
            left: -146px;
            top: 50%;
          }
        }


        .login-links {
          z-index: 100;
          top: 2px;
          transition: top 300ms, opacity 300ms;

          .signin-linkAB {
            display: inline;
            padding: 6px 15px;
          }

          .create-account-link, .react-signup-topnav {
            padding: 6px 25px;
          }

          .create-account-linkAB {
            display: none;
          }
        }

        .search-form-wrapper {
          margin-left: 6px;
        }

        .var_0 {
          top: -15px;
        }

        .upgrade-link {
          display: none !important;
        }

        .button {
          min-height: 40px;
          margin-bottom: 10px;
        }

        .button-inner {
          height: 40px;
        }

        .hamburger {
          right: 0;
          top: 8px
        }
      }

      &.expanded {
        .container {
          .login-links {
            display: none;
          }

          .logo-holder {
            width: 105px;

            .icon-edu-logo {
              display: none;
            }

            .icon-logo-mark {
              display: inline-block;
              color: @color-teal;
            }
          }
        }
      }
    }

    nav.reorg-subnav {
      .long-nav {
        display: none !important;
      }
    }
  }

  .Header {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 0;
    height: 80px;
    border-bottom: 1px solid @color-grayD;
    align-items: center;
    justify-content: center;
  }

  .NavBar {
    display: flex;
    align-items: center;
    padding-right: (@spacing-unit * 2);
    padding-left: (@spacing-unit * 2);
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    width: 100%;
    max-width: (@screen-lg-min - (@spacing-unit * 3));

    @media (min-width: @screen-md-min) {
      padding-right: (@spacing-unit * 3);
      padding-left: (@spacing-unit * 3);
    }
  }

  .NavBar__logo {
    width: 1.5em;
    min-width: 1.5em;
    font-size: 38px;
    color: @color-primary;
    margin-top: -10px;

    &:hover,
    &:active {
      color: @color-primary;
    }

    .icon-edu-logo {
      display: none;
    }

    @media (min-width: @screen-sm-min) {
      width: 200px;
      min-width: 200px;

      .icon-edu-logo {
        display: block;
      }

      .icon-logo-mark {
        display: none;
      }
    }
  }

  .NavBar__spacer {
    flex-grow: 1;
  }

  .NavBar__profile {
    display: flex;
    align-items: center;
    margin: 0 (@spacing-unit * 2);
    font-weight: bolder;
    min-width: 0;
  }

  .NavBar__profile-email {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .hide-on-mobile {
      @media (max-width: 750px) {
        display: none;
      }
    }
  }

  .NavBar__logout {
    text-align: center;
  }

  main#content {
    min-height: 400px;
  }

  .third-line {
    top: 10px;
  }

  .subjects-dropdown-mixin() {
    background-color: white;
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px 0 0 4px;
    display: block;
    left: -400px;
    opacity: 0;
    right: auto;
    user-select: none;
    z-index: -1;

    @media (max-width: (@screen-homepage)) {
      background-color: @color-ecru;
      border: 0;
      box-shadow: none;
      overflow-y: auto;
      padding-top: 0;
      padding-left: 0;
      position: fixed;
      right: auto;
      transition: left .3s, opacity .3s;
      top: 220px;
    }

    & > ul {
      list-style: none;
      margin-top: 20px;
      padding: 0 0 30px 0;
      width: 250px;

      @media (max-width: (@screen-homepage)) {
        margin-top: 4px;
      }

      & > li {
        background: none;
        border-right: 1px solid @color-light-grayDB;
        padding: 5px 0;

        @media (max-width: (@screen-homepage)) {
          border-right: 0;
          border-bottom: 0;
          padding: 0;
          margin-bottom: 5px;
        }

        &.open {
          background-color: @color-blue-sapphire;

          @media (max-width: (@screen-homepage)) {
            background-color: transparent;
          }

          & > span {
            color: white;

            @media (max-width: (@screen-homepage)) {
              color: @color-gray-primary;
              padding-bottom: 0;
            }

            i {
              &.icon-angle-up {
                @media (max-width: (@screen-homepage)) {
                  display: inline-block;
                }
              }

              &.icon-angle-down {
                display: none;
              }
            }
          }
        }

        & > span, & > a {
          color: @color-gray-primary;
          font-size: 20px;
          font-weight: normal;
          padding-left: 30px;

          &:hover {
            color: @color-blue-sapphire;
            font-weight: bold;

            @media (max-width: (@screen-homepage)) {
              color: @color-gray6;
            }
          }

          @media (max-width: (@screen-homepage)) {
            font-size: 20px;
            margin-bottom: 5px;
            padding: 0 20px 10px;
          }

          i {
            &.icon-angle-up {
              display: none;
            }

            &.icon-angle-down {
              display: none;
              @media (max-width: (@screen-homepage)) {
                display: inline-block;
              }
            }

            &.icon-angle-right {
              display: inline-block;
              @media (max-width: (@screen-homepage)) {
                display: none;
              }
            }
          }
        }

        .sublinks {
          background-color: white;
          box-shadow: 4px 3px 1px 0 rgba(0, 0, 0, 0.12), 4px 2px 2px 0 rgba(0, 0, 0, 0.14), 4px 1px 5px 0 rgba(0, 0, 0, 0.2);
          border-radius: 0 4px 4px 0;
          left: 100%;
          width: 400px;

          @media (max-width: (@screen-homepage)) {
            background-color: @color-ecru;
            box-shadow: none;
            margin: 0 0 0 10px;
            position: static;
          }

          ul {
            margin: 31px 0 0 10px;
            list-style: none;

            @media (max-width: (@screen-homepage)) {
              display: none;
              margin: 0;
              padding: 0 0 0 13px;

              &:first-of-type {
                display: inline-block;
              }
            }

            li {
              color: @color-teal;
              font-family: inherit;
              padding: 5px 0;
              width: 100%;

              @media (max-width: (@screen-homepage)) {
                padding: 0;
              }

              &.header {
                font-weight: bold;
                @media (max-width: (@screen-homepage)) {
                  display: none;
                }
              }

              a {
                color: @color-gray6;
                font-size: 16px;

                &:hover {
                  background: unset;
                }

                @media (max-width: (@screen-homepage)) {
                  color: @color-blue-sapphire;
                  line-height: 22px;
                  margin: 5px 0;
                }
              }
            }
          }
        }
      }
    }
  }


  header.reorg {
    .container {

      .buttons-wrapper {
        .react-login, .react-signin-topnav {
          @media (max-width: @screen-homepage) {
            visibility: hidden;
            opacity: 0;
            transition: visibility 300ms linear 0s, opacity 300ms linear 0s;
          }
        }

        .react-signup, .react-signup-topnav {
          @media (max-width: @screen-homepage) {
            font-family: @font-family-bold;
            font-weight: normal;
            top: 2px;
            width: 122px;
            height: 38px;
            margin-left: 20px;
            margin-right: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top:3px;
          }

          @media (max-width: @screen-phone-lg) {
            width: 100px;
            height: 42px;
            margin-left: 15px;
            margin-right: 7px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }

    &.expanded {
      .container {
        @media (max-width: @screen-homepage) {
          .buttons-wrapper {
            z-index: 100;

            > div {
              display: none;
            }
          }
        }

        @media (max-width: @screen-phone-lg) {
          .buttons-wrapper > .login-links {
            .react-login, .react-signin-topnav {
              visibility: visible;
              opacity: 1;
            }
          }
        }
      }
    }

    &[data-account-type="Anonymous"].expanded {
      .container {
        .logo-holder {
          width: 36px;
        }
        .search-form-container {
          display: none;
          width: 0;
        }
        .hamburger {
          top: 3px;
        }
        .buttons-wrapper {
          .login-links {
            display: block;

            .react-login, .react-signin-topnav {
              display: none;
              @media (max-width: @screen-homepage) {
                visibility: visible;
                opacity: 1;
                display: inline-block !important;
                font-family:  @font-family-bold;
              }
            }

            .react-signup, .react-signup-topnav {
              display: none;
              @media (max-width: @screen-homepage) and (min-width: 481px) {
                visibility: visible;
                opacity: 1;
                display: inline-block !important;
                margin-right: 60px;
                padding-top: 10px;
              }
              @media (max-width: 480px) {
                visibility: visible;
                opacity: 1;
                display: inline-block !important;
                margin-right: 65px;
                padding-top: 12px;
              }
            }

            .react-signup.mobile, .react-signup-topnav.mobile {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: @screen-phone-lg) {
    header.reorg {
      .container {
        overflow-x: clip;
      }

      .logo-holder {
        .icon-logo-mark {
          position: absolute;
          width: 36px;
        }
      }

      &.expanded {
        .logo-holder {
          width: 36px;
          .icon-edu-logo {
            visibility: hidden;
            opacity: 0;
          }

          // When MobileNavMenu is open, show the logo icon (pencil-only) on phone widths
          .icon-logo-mark {
            visibility: visible;
            transition: visibility 0s linear 0s !important;
          }
        }
      }

      // "Try Premium" button animation
      &[data-account-type="Basic"] {
        .upgrade-link {
          display: none;
        }

        &.expanded .upgrade-link {
          visibility: hidden;
        }
      }

      .search-form-container.search-bar-expanded {
        width: calc(100vw - 74px);
        transition: width 400ms;
      }
    }
  }

  .skip-to-main-content:not(:focus) {
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    padding: 0
  }

  .skip-to-main-content:hover {
    color: @color-blue-sapphire;
    text-decoration: underline
  }

  .skip-to-main-content, .skip-to-main-content:visited {
    background-color: @color-white;
    border: 1px solid @color-blue-sapphire;
    border-radius: 2px;
    color: @color-blue-sapphire;
    cursor: pointer;
    font-size: 14px;
    height: 73px;
    opacity: .9;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 145px;
    z-index: 101;
  }

}

.v1 {
  body {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  header.reorg {
    background: #fff;
    color: @color-gray-primary;
    box-sizing: content-box;
    height: 55px;
    position: relative;
    padding: 30px 20px 0;
    margin: auto auto 2px;

    @media (max-width: (@screen-homepage)) {
      padding: 0;
      margin: 0;
    }

    .buttons-wrapper {
      display: flex;
      gap: 10px;

      @media (max-width: (@screen-homepage)) {
        position: absolute;
        right: 46px;
        top: 1px;
        padding-right: 0;
        margin-right: 10px;
        display: flex;
        justify-content: space-between;
      }

      @media (max-width: @screen-phone-lg) {
        margin-right: 10px;
      }
    }

    .icon-diamond, .icon-diamond-outline {
      vertical-align: middle;
      color: @color-teal;
      font-size: 12px;

      &.basic, &.anonymous {
        color: @color-magenta;
      }
    }

    .btn-teal {
      background-color: @color-dark-teal;

      &:hover:not(.disabled) {
        background-color: @color-dark-teal;
      }
    }

    .container {
      display: flex;
      line-height: 40px;
      max-width: 1150px;
      margin: auto;

      .search-form-wrapper {
        display: flex;
        width: 100%;
        z-index: 13;
      }

      .search-form-container {
        width: 100%;
        min-width: 180px;

        ::placeholder {
          color: #c5c5c5;
        }

        position: relative;

        &:not(.active) {
          .tt-menu {
            // Hide dropdown when the container is not active (SubNav can close it)
            display: none !important;
          }
        }

        form {
          position: relative;

          input {
            border-radius: 40px;
            width: 100%;
            border: 1px solid @color-grayC5;
            box-sizing: border-box;
            background: #fff;
            padding-left: 40px;
            line-height: 40px;
            height: 42px;
            font-size: 16px;
            outline: none;

            &:hover, &:active, &:focus {
              border-color: @color-interactive;
            }

            &.top-nav-search {
              display: inline-block;
              padding-right: 40px;
              text-overflow: ellipsis;
            }
          }

          .icon-search {
            position: absolute;
            color: @color-interactive;
            top: 0;
            left: 10px;
          }

          .icon-cancel-circled {
            display: none;
            position: absolute;
            color: @color-interactive;
            top: 0;
            right: 10px;
            font-size: 24px;
          }
        }
      }

      .hamburger {
        display: none;
        position: absolute;
        right: 20px;
        top: 2px;
        z-index: 1000;
        padding: 0;
      }

      .logo-holder {
        display: flex;
        align-items: flex-end;
        width: 186px;
        font-size: 38px;
        user-select: none;
        margin-right: 10px;
        margin-top: -2px;
        margin-bottom: 12px;
        z-index: 100;
        flex-shrink: 0;

        .icon-edu-logo {
          color: @color-teal;

          &:before {
            margin-left: 0;
          }
        }

        .icon-logo-mark {
          display: none;
        }

        &:hover {
          text-decoration: none;
        }
      }

      .who-we-are {
        position: relative;
        display: none;
      }

      .progress-tracker, .kid-mode-button {
        cursor: pointer;
        padding: 30px 10px;

        @media (max-width: (@screen-homepage) ) {
          padding: 0 20px !important;
        }


        a {
          color: @color-gray-primary;
          font-size: 18px;
          text-decoration: none;

          &:hover {
            color: @color-blue-sapphire;
            text-decoration: none;
          }
        }
      }

      .guidance {
        position: relative;
        padding-right: 0;
      }

      .upgrade-link {
        display: none;
      }

      .login-links {
        vertical-align: top;
        flex: 1 auto;
        box-sizing: border-box;
        text-align: right;
        display: none;
        white-space: nowrap;

        .signin, .react-signin-topnav {
          color: @color-text-primary;
          font-family: TTNorms, Arial, sans-serif;

          &:hover {
            text-decoration: none;
            color: @color-interactive;
          }
        }

        @media (max-width: (@screen-homepage)) {
          > a {
            font-size: 14px;
          }

          .react-signup, .react-signup-topnav {
            padding: 5px 28px;
          }

          .signin, .react-signin-topnav {
            margin: 0 20px;
            padding: 0;
          }
        }
      }

      .brainzy-access {
        width: 137px;
        display: inline-block;
        position: relative;

        .brainzy-link {
          cursor: pointer;
          position: relative;

          &.var_0 {
            height: 50px;
            top: -10px;

            .button {
              position: absolute;
              width: 125px;
              height: 35px;
              border: 1px solid #5672c4;
              box-shadow: 0 0 2px 0 rgba(0, 0, 0, .06), 0 2px 2px 0 rgba(0, 0, 0, .12);
              right: 0;
              top: 15px;
              border-radius: 35px;
              transition: 150ms all;
            }

            .button-inner {
              width: 100%;
              height: 55px;
              position: absolute;
              bottom: 0;
            }

            .roly-clip {
              position: absolute;
              left: -1px;
              bottom: 0;
              border-radius: 17px;
              overflow: hidden;
              width: 100px;
              height: 55px;
              -webkit-mask-image: -webkit-radial-gradient(white, black);
            }

            .roly {
              width: 47px;
              height: 44px;
              position: absolute;
              bottom: -1px;
              left: -3px;
              transform-origin: -5px 50px;
              transition: 150ms transform;
            }

            .kids {
              position: absolute;
              right: 8px;
              top: 18px;
              width: 75px;
              height: 37.5px;
              transform-origin: -20px 100%;
              transition: 150ms transform;
            }
          }

          &.var_1 {
            img {
              position: relative;
              width: 100px;
              height: 34px;
              transition: 150ms all;
              margin-top: 2px;
            }
          }

          &:hover {
            &.var_0 {
              .button {
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, .28);
              }

              .roly {
                transform: scale(1.06);
              }

              .kids {
                transform: scale(1.03);
              }
            }

            &.var_1 {
              img {
                filter: drop-shadow(0 3px 1px rgba(0, 0, 0, .15));
                transform: scale(1.04);
              }
            }
          }
        }

        &.mobile {
          margin: -5px 0 -5px 30px;
          transform: scale(1.25);
          display: none;

          .brainzy-link.var_0 {
            left: 5px;
            width: 125px;
            margin: 25px 0 15px 0;
          }

          .brainzy-link.var_1 {
            img {
              top: 2px;
            }
          }

          div {
            padding: 0 !important;
          }
        }

        @media (max-width: (@screen-homepage)) {
          &.mobile {
            display: block;
          }
        }
      }

      .hamburger-brainzy .brainzy-access {
        @media (max-width: (@screen-homepage)) {
          display: block;
          margin-left: 50px;
        }
      }

      .brainzy-access {
        @media (max-width: (750px)) {
          display: none;
        }

        @media (max-width: @screen-homepage) {
          transform: scale(0.88);
          transform-origin: right;
        }
      }

      .user-menu {
        max-width: 200px;
        z-index: 98;
        cursor: pointer;
        position: relative;

        .num-notifications {
          display: inline-block;
          width: 18px;
          height: 18px;
          line-height: 18px;
          text-align: center;
          background: @color-dark-teal;
          border-radius: 20px;
          color: #fff;
          font-weight: bold;
          font-size: 14px;
          vertical-align: middle;

          &:empty {
            visibility: hidden;
          }
        }

        .tab-text {
          text-align: right;
          position: relative;
          white-space: nowrap;

          .email-avatar {
            margin-bottom: -2px;
            padding-right: 35px;
            line-height: 20px;

            .email {
              color: @color-gray-primary;
              display: inline-block;
              max-width: 100px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              vertical-align: middle;
              font-weight: bold;
              line-height: 24px;
              font-size: 14px;
            }

            .user-icon {
              position: absolute;
              right: 0;
              top: 7px;
            }

            .num-notifications {
              position: absolute;
              right: -4px;
              top: 2px;
            }
          }

          .premium-label {
            line-height: 14px;
            padding-right: 35px;

            .icon-diamond-outline {
              font-size: unset;
            }

            span {
              display: inline-block;
              background: @color-dark-teal;
              color: #fff;
              padding: 3px 4px 1px;
              font-size: 9px;
              line-height: 12px;
              font-weight: bold;
              letter-spacing: 0.5px;
              border-radius: 2px;
              vertical-align: middle;
              text-transform: uppercase;
            }
          }
        }

        &.active {
          .tab-text {
            .email-avatar {
              .num-notifications {
                display: none;
              }
            }
          }

          .dropdown {
            display: block;
          }
        }
      }

      .notification-modal {
        text-align: left;
        cursor: default;

        .modal-header {
          display: flex;
          margin-bottom: 25px;
          width: 100%;
          justify-content: space-between;

          h3 {
            margin: 0;
          }

          .delete-all-notifications {
            display: none;
            color: @color-blue-sapphire;
            font-weight: 600;
            cursor: pointer;
            font-size: 16px;
          }
        }

        .notifications-list {
          border-bottom: 1px solid @color-light-grayED;

          .notification {
            display: flex;
            justify-content: space-between;
            position: relative;
            text-align: left;
            line-height: 20px;
            border-top: 1px solid @color-light-grayED;
            padding: 10px;
            align-items: center;

            a {
              display: flex;
              align-items: center;
            }

            .notification-delete {
              cursor: pointer;
              font-size: 16px;
              color: @color-dark-gray87;
              margin-left: 40px;
              padding: 8px 7px;
              border-radius: 25px;

              &:hover {
                background-color: rgba(86, 114, 196, 0.1);
              }
            }
          }
        }
      }
    }

    &.no-search {
      .search-form-container form {
        @media (min-width: @screen-homepage) {
          display: none;
        }
      }
    }

    &[data-account-type="Anonymous"] {
      .container {
        .who-we-are {
          display: block;
        }

        .login-links {
          display: block;
        }

        .brainzy-access {
          display: none;
        }
      }
    }

    &[data-account-type="Basic"] {
      .container {
        .upgrade-link {
          display: block;
          white-space: nowrap;
          @media (max-width: 480px) {
            display: none;
          }
        }

        .user-menu .tab-text .email-avatar .email {
          margin-top: 10px;
        }

        .user-menu {
          .tab-text {

            .premium-label {
              display: none;
            }

            .email-avatar {
              .num-notifications {
                top: -5px;
              }
            }
          }

          .dropdown {
            ul {
              li {
                .icon-diamond, .icon-diamond-outline {
                  display: inline-block;
                  margin-right: 3px;
                }
              }

              li.premium {
                order: 10;
              }

              li.premium.disabled {
                color: silver;
              }

              li.notifications-row {
                border-top: 0;
                padding-top: 5px;
                margin-top: 0;
              }

              li.premium-header {
                order: 8;
                display: block;
                margin-top: 10px;
                border-top: 1px solid rgba(0, 0, 0, .1);
                padding-top: 15px;
                padding-bottom: 10px;

                span {
                  text-transform: uppercase;
                  color: @color-dark-gray87;
                  font-size: 9px;
                  font-weight: bold;
                  line-height: 12px;
                  letter-spacing: 1px;
                  vertical-align: middle;
                }

                i {
                  vertical-align: middle;
                }
              }

              li.upsell {
                order: 15;
                display: block;
                padding-top: 15px;

                a {
                  display: block;
                  font-size: 14px;
                  padding: 6px 16px;
                }
              }

              li.tools-for-teaching {
                order: 16;
                padding-top: 7px;
              }

              li.signout {
                order: 20;
              }
            }
          }
        }
      }
    }

    /*!
     * Hamburgers
     * @description Tasty CSS-animated hamburgers
     * @author Jonathan Suh @jonsuh
     * @site https://jonsuh.com/hamburgers
     * @link https://github.com/jonsuh/hamburgers
    */

    .hamburger {
      top: 2px;
      display: inline-block;
      cursor: pointer;
      transition-property: opacity, filter;
      transition-duration: 0.15s;
      transition-timing-function: linear;
      font: inherit;
      color: inherit;
      text-transform: none;
      background-color: transparent;
      border: 0;
      margin: 0;
      overflow: visible;
    }

    .hamburger-box {
      width: 26px;
      height: 24px;
      display: inline-block;
      position: relative;
    }

    .hamburger-inner {
      display: block;
      top: 50%;
      margin-top: 8px;
    }

    .hamburger-inner, .hamburger-inner::after {
      width: 25px;
      height: 3px;
      background-color: @color-blue-sapphire;
      border-radius: 0;
      position: absolute;
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    .hamburger-inner::after {
      content: "";
      display: block;
    }

    .hamburger-inner::after {
      bottom: -10px;
    }

    .hamburger--slider .hamburger-inner {
      top: 0px;
    }

    .hamburger--slider .third-line {
      top: 10px;
    }

    .hamburger--slider .hamburger-inner::after {
      top: 10px;
    }

    &.expanded {
      .hamburger--slider .hamburger-inner.first-line {
        transform: translate3d(1px, 9px, 0) rotate(45deg);

        &:after {
          display: none;
        }
      }

      .hamburger--slider .hamburger-inner.third-line {
        transform: translate3d(1px, -1px, 0) rotate(-45deg);

        &:after {
          display: none;
        }
      }
    }
  }

  .guided-library-contain {
    display: flex;
    flex-direction: row-reverse;
    @media (max-width: (@screen-homepage)) {
      flex-direction: column;
      flex-shrink: 0;
    }
  }

  @media (max-width: 1199px) {
    header.reorg {
      .container {
        padding: 0;

        .tab {
          .tab-text {
            i {
              margin-left: -2px;
            }
          }

          .dropdown {
            .flex-grid .col {
              padding-right: 8px;

              &.left-side {
                min-width: 60%;
                margin-right: 30px;

                > .flex-grid > .col:first-of-type {
                  flex-basis: 8%;
                }
              }
            }
          }
        }
      }

      &[data-account-type="Anonymous"], &[data-account-type="Basic"] {
        .container {
          .user-menu .tab-text .email-avatar .email {
            margin-top: 10px;
          }

          .search-form-container {
            form {
              input {
                font-size: 16px;
                padding-left: 40px;
              }

              .icon-cancel-circled {
                right: 10px;
              }
            }
          }
        }
      }
    }

    nav.reorg-subnav {
      font-size: 14px;
      padding: 0 25px;

      .container {
        ul {
          li {
            padding: 0 10px;

            &:first-child {
              padding-left: 0;
            }

            &:last-child {
              padding-right: 0;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1100px) {
    header.reorg {
      .container {
        .search-form-container {
          form {
            input {
              .icon-cancel-circled {
                right: 10px;
              }

              &.top-nav-search {
                display: inline-block;
              }
            }
          }
        }
      }
    }

    nav.reorg-subnav {
      .container {
        ul {
          li {
            padding: 0 6px;
          }
        }
      }
    }
  }

  @media (max-width: (@screen-homepage) ) {
    header.reorg {
      height: 110px;

      .container {
        margin-top: 20px;
        padding: 0 20px;

        .search-form-container {
          form {
            input {
              display: none;

              .solid-button-standard .hidden {
                display: none;
              }
            }
          }
        }

        .progress-tracker, .kid-mode-button {
          margin-bottom: 5px;
          padding: 0 20px !important;

          a {
            font-size: 20px;
          }
        }


        .logo-holder {
          width: 175px;
          font-size: 37px;
          position: absolute;
          margin-left: 20px;
          margin-bottom: 0;
          margin-top: 0;
          left: 0;

          .icon-edu-logo, .icon-logo-mark {
            vertical-align: -1px;
          }


        }

        .hamburger {
          display: inline-block;
          right: 19px;
        }

        .search-form-container {

          @media (max-width: @screen-desktop) {
            top: 20px;
          }

          form {
            position: absolute;
            top: 32px;
            left: 0;
            width: ~"calc(100% - 40px)";
            box-sizing: border-box;
            padding: 0 20px;

            @media (max-width: 480px) {
              // If anyone sees this disorganized mess: this is to line up the search bar with the other menu items.
              // Please don't blame me... this file is already such a mess I don't have it in me to update :(
              width: ~"calc(100% - 32px)";
              padding: 0 16px;
            }

            input {
              width: 100% !important;
              padding-left: 35px !important;
            }

            .icon-search {
              left: 30px;
            }

            .icon-cancel-circled {
              line-height: 42px;
              right: 0px !important;
              left: calc(100% - 30px);
            }

            span.twitter-typeahead {
              position: absolute !important;
              width: 100%;
            }
          }
        }

        .login-links {
          position: relative;
        }

        .upgrade-link {
          a {
            padding: 6px 12px;
            font-size: 14px;
          }
        }
      }

      &.expanded {
        .hamburger {
          margin-top: 15px;
          position: fixed !important;
        }
      }
    }

    nav.reorg-subnav {
      padding: 0 20px;

      .container {
        margin: 0;
        font-size: 15px;

        .popular {
          margin-right: 0;

          li {
            &.header {
              .extra-text {
                display: inline;
              }
            }
          }
        }

        .grades {
          display: none;
        }
      }
    }
  }

  @media (max-width: 530px) {
    header.reorg {
      .container {
        .logo-holder {
          font-size: 30px;
          width: 142px;
        }
      }
    }
  }

  @media (max-width: 750px) {
    nav.reorg-subnav {
      .container {
        padding: 6px 0;
        max-width: none;
        text-align: left; //center;
        ul {
          display: block;
        }

        .popular {
          li {
            font-size: 14px;
            line-height: 17px;

            &.header {
              display: block;
              font-size: 12px;
              line-height: 18px;
              padding-left: 6px;

              .extra-text {
                display: none;
              }

              .extra-text-small {
                display: inline;
              }
            }

            &.back-to-school-topnav {
              margin-top: -5px;
            }
          }
        }

        .grades {
          display: none;
        }
      }
    }
  }

  .react-signup-topnav {

    &.mobile {
      display: none;

      @media (max-width: 320px) {
        display: inline-block;
      }
    }

    @media (max-width: 320px) {
      display: none;
    }
  }

  @media (max-width: @screen-phone-lg) {
    header.reorg {
      .container {
        padding: 0 10px;

        .user-menu {
          &.active {
            background: transparent;
          }

          &:hover {
            background: transparent;
          }

          width: 50px;
          height: 28px;

          .tab-text {
            .email-avatar {
              margin-bottom: 0px;

              .email {
                display: none;
              }
            }

            .premium-label {
              display: none;
            }
          }

          .dropdown {
            // Magic numbers :)
            left: -146px;
            top: 50%;
          }
        }


        .login-links {
          z-index: 100;
          top: 2px;
          transition: top 300ms, opacity 300ms;

          .signin-linkAB {
            display: inline;
            padding: 6px 15px;
          }

          .create-account-link, .react-signup-topnav {
            padding: 6px 25px;
          }

          .create-account-linkAB {
            display: none;
          }
        }

        .logo-holder {
          z-index: 99;
          margin-left: 16px;
        }

        .upgrade-link {
          padding: 7px 0;
        }

        .hamburger {
          right: 15px;
        }
      }

      &[data-account-type="Anonymous"] {
        &.expanded {
          .container {
            .login-links {
              display: none;
            }

            .logo-holder {
              width: 105px;

              .icon-logo-mark {
                display: inline-block;
                color: @color-teal;
              }
            }
          }
        }
      }
    }

    nav.reorg-subnav {
      .long-nav {
        display: none !important;
      }
    }
  }

  .Header {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    padding: 10px 0;
    height: 80px;
    border-bottom: 1px solid @color-grayD;
    align-items: center;
    justify-content: center;
  }

  .NavBar {
    display: flex;
    align-items: center;
    padding-right: (@spacing-unit * 2);
    padding-left: (@spacing-unit * 2);
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
    width: 100%;
    max-width: (@screen-lg-min - (@spacing-unit * 3));

    @media (min-width: @screen-md-min) {
      padding-right: (@spacing-unit * 3);
      padding-left: (@spacing-unit * 3);
    }
  }

  .NavBar__logo {
    width: 1.5em;
    min-width: 1.5em;
    font-size: 38px;
    color: @color-primary;
    margin-top: -10px;

    &:hover,
    &:active {
      color: @color-primary;
    }

    .icon-edu-logo {
      display: none;
    }

    @media (min-width: @screen-sm-min) {
      width: 200px;
      min-width: 200px;

      .icon-edu-logo {
        display: block;
      }

      .icon-logo-mark {
        display: none;
      }
    }
  }

  .NavBar__spacer {
    flex-grow: 1;
  }

  .NavBar__profile {
    display: flex;
    align-items: center;
    margin: 0 (@spacing-unit * 2);
    font-weight: bolder;
    min-width: 0;
  }

  .NavBar__profile-email {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .hide-on-mobile {
      @media (max-width: 750px) {
        display: none;
      }
    }
  }

  .NavBar__logout {
    text-align: center;
  }

  main#content {
    min-height: 400px;
  }

  .third-line {
    top: 10px;
  }

  .subjects-dropdown-mixin() {
    background-color: white;
    box-shadow: 0 3px 1px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.14), 0 1px 5px rgba(0, 0, 0, 0.2);
    border-radius: 4px 0 0 4px;
    display: block;
    left: -400px;
    opacity: 0;
    right: auto;
    user-select: none;
    z-index: -1;

    @media (max-width: (@screen-homepage)) {
      background-color: @color-ecru;
      border: 0;
      box-shadow: none;
      overflow-y: auto;
      padding-top: 0;
      padding-left: 0;
      position: fixed;
      right: auto;
      transition: left .3s, opacity .3s;
      top: 220px;
    }

    & > ul {
      list-style: none;
      margin-top: 20px;
      padding: 0 0 30px 0;
      width: 250px;

      @media (max-width: (@screen-homepage)) {
        margin-top: 4px;
      }

      & > li {
        background: none;
        border-right: 1px solid @color-light-grayDB;
        padding: 5px 0;

        @media (max-width: (@screen-homepage)) {
          border-right: 0;
          border-bottom: 0;
          padding: 0;
          margin-bottom: 5px;
        }

        &.open {
          background-color: @color-blue-sapphire;

          @media (max-width: (@screen-homepage)) {
            background-color: transparent;
          }

          & > span {
            color: white;

            @media (max-width: (@screen-homepage)) {
              color: @color-gray-primary;
              padding-bottom: 0;
            }

            i {
              &.icon-angle-up {
                @media (max-width: (@screen-homepage)) {
                  display: inline-block;
                }
              }

              &.icon-angle-down {
                display: none;
              }
            }
          }
        }

        & > span, & > a {
          color: @color-gray-primary;
          font-size: 20px;
          font-weight: normal;
          padding-left: 30px;

          &:hover {
            color: @color-blue-sapphire;
            font-weight: bold;

            @media (max-width: (@screen-homepage)) {
              color: @color-gray6;
            }
          }

          @media (max-width: (@screen-homepage)) {
            font-size: 20px;
            margin-bottom: 5px;
            padding: 0 20px 10px;
          }

          i {
            &.icon-angle-up {
              display: none;
            }

            &.icon-angle-down {
              display: none;
              @media (max-width: (@screen-homepage)) {
                display: inline-block;
              }
            }

            &.icon-angle-right {
              display: inline-block;
              @media (max-width: (@screen-homepage)) {
                display: none;
              }
            }
          }
        }

        .sublinks {
          background-color: white;
          box-shadow: 4px 3px 1px 0 rgba(0, 0, 0, 0.12), 4px 2px 2px 0 rgba(0, 0, 0, 0.14), 4px 1px 5px 0 rgba(0, 0, 0, 0.2);
          border-radius: 0 4px 4px 0;
          left: 100%;
          width: 400px;

          @media (max-width: (@screen-homepage)) {
            background-color: @color-ecru;
            box-shadow: none;
            margin: 0 0 0 10px;
            position: static;
          }

          ul {
            margin: 31px 0 0 10px;
            list-style: none;

            @media (max-width: (@screen-homepage)) {
              display: none;
              margin: 0;
              padding: 0 0 0 13px;

              &:first-of-type {
                display: inline-block;
              }
            }

            li {
              color: @color-teal;
              font-family: inherit;
              padding: 5px 0;
              width: 100%;

              @media (max-width: (@screen-homepage)) {
                padding: 0;
              }

              &.header {
                font-weight: bold;
                @media (max-width: (@screen-homepage)) {
                  display: none;
                }
              }

              a {
                color: @color-gray6;
                font-size: 16px;

                &:hover {
                  background: unset;
                }

                @media (max-width: (@screen-homepage)) {
                  color: @color-blue-sapphire;
                  line-height: 22px;
                  margin: 5px 0;
                }
              }
            }
          }
        }
      }
    }
  }


  header.reorg {
    .container {
      @media (max-width: @screen-phone-lg) {
        margin-top: 7px;
      }

      .buttons-wrapper {
        .react-login, .react-signin-topnav, .react-signup, .react-signup-topnav {
          @media (max-width: @screen-homepage) {
            font-family: @font-family-bold;
            font-weight: normal;
          }
        }

        .react-login, .react-signin-topnav {
          @media (max-width: @screen-phone-lg) {
            visibility: hidden;
            opacity: 0;
            transition: visibility 300ms linear 0s, opacity 300ms linear 0s;
          }
        }
      }
    }

    &.expanded {
      .container {
        @media (max-width: @screen-homepage) {
          .buttons-wrapper {
            z-index: 100;

            > div {
              display: none;
            }
          }
        }

        @media (max-width: @screen-phone-lg) {
          .buttons-wrapper > .login-links {
            .react-login, .react-signin-topnav {
              visibility: visible;
              opacity: 1;
              transition: visibility 300ms linear 200ms, opacity 300ms linear 200ms;
            }
          }
        }
      }
    }

    &[data-account-type="Anonymous"].expanded {
      .container {
        .buttons-wrapper {
          .login-links {
            display: block;

            .react-login, .react-signin-topnav, .react-signup, .react-signup-topnav {
              display: none;
              @media (max-width: @screen-phone-lg) {
                display: inline-block;
              }
            }

            .react-signup.mobile, .react-signup-topnav.mobile {
              display: none;
            }
          }
        }
      }
    }
  }

  @media (max-width: @screen-phone-lg) {
    header.reorg {
      // Logo animation
      .logo-holder {
        z-index: 101 !important;

        .icon-edu-logo {
          display: inline-block;
          visibility: visible;
          opacity: 1;
          transition: visibility 0.5s linear 0.5s, opacity 0.3s linear 0.2s;
        }

        .icon-logo-mark {
          display: inline-block !important;
          color: @color-teal;
          visibility: hidden;
          transition: visibility 0s linear 0.5s;
          position: absolute;

          &:before {
            margin: 0;
          }
        }
      }

      &.expanded {
        .logo-holder {
          .icon-edu-logo {
            visibility: hidden;
            opacity: 0;
          }

          // When MobileNavMenu is open, show the logo icon (pencil-only) on phone widths
          .icon-logo-mark {
            visibility: visible;
            transition: visibility 0s linear 0s !important;
          }
        }
      }

      &:not([data-account-type="Anonymous"]) {
        // User icon animation
        .user-menu {
          max-width: 500px;
          transition: visibility 300ms linear 300ms, opacity 300ms linear 300ms, max-width 0s linear 300ms;
          visibility: visible;
          opacity: 1;
        }

        &.expanded .user-menu {
          gap: 0;
          max-width: 0;
          opacity: 0;
          visibility: hidden;
          transition: visibility 0s 0s, opacity 0s 0s, max-width 0s 0s;
        }
      }

      // "Try Premium" button animation
      &[data-account-type="Basic"] {
        .upgrade-link {
          display: block !important;
          opacity: 0;
          visibility: hidden;
          transition: visibility 0s 0s, opacity 0s 0s;
        }

        &.expanded .upgrade-link {
          transition: visibility 300ms linear 300ms, opacity 300ms linear 300ms;
          visibility: visible;
          opacity: 1;
          padding: 0;

          > a {
            padding: 6px 25px;
            width: 131px;
          }
        }
      }
    }
  }

  .skip-to-main-content:not(:focus) {
    clip: rect(1px, 1px, 1px, 1px);
    overflow: hidden;
    padding: 0
  }

  .skip-to-main-content:hover {
    color: @color-blue-sapphire;
    text-decoration: underline
  }

  .skip-to-main-content, .skip-to-main-content:visited {
    background-color: @color-white;
    border: 1px solid @color-blue-sapphire;
    border-radius: 2px;
    color: @color-blue-sapphire;
    cursor: pointer;
    font-size: 14px;
    height: 73px;
    opacity: .9;
    top: 0;
    left: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    width: 145px;
    z-index: 101;
  }
}
