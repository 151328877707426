@import "colors.less";
@import "screen-sizes.less";

i.circle-icon {
  border-radius: 40px;
  display: inline-block;
  color: white;

  &.icon-story {
    background-color: @color-gray-primary;

    &:before {
      font-size: 80%;
      margin-left:-.1em;
      margin-right:.5em;
      padding: 0.7em 0.5em;
    }
  }

  &.icon-star {
    background-color: @color-gray-primary;

    &:before {
      font-size: 110%;
      padding: 0.35em 0.15em;
    }
  }

  &.icon-music {
    background-color: @color-gray-primary;
    padding: 0.25em 0.2em;
  }

  &.icon-lesson-plan {
    background-color: @color-gray-primary;
    padding: 0.4em 0.3em 0.2em 0.2em;
    @media print{
      display: none;
    }

    @media (max-width: (@screen-tablet - 1)) {
      display:none;
    }
  }

  &.icon-workbook {
    background-color: @color-gray-primary;
    padding: 0.3em 0.4em 0.4em;

    &:before {
      font-size: 80%;
      margin-left:-.1em;
      margin-right:.5em;
    }
  }

  &.icon-worksheet {
    background-color: @color-gray-primary;

    &:before {
      padding: 0.4em 0.2em 0.4em 0.25em;
    }
  }

  &.icon-activity {
    background-color: @color-gray-primary;

    &:before {
      padding: 0.4em 0.25em 0.4em 0.2em;
    }
  }

  &.icon-science-project {
    background-color: @color-gray-primary;

    &:before {
      padding: 0.45em 0.3em 0.45em 0.2em;
    }
  }

  &.icon-skill-builder {
    background-color: @color-gray-primary;

    &:before {
      padding: 0.35em 0.2em;
    }
  }

  &.icon-set {
    background-color: @color-gray-primary;

    &:before {
      padding: 0.4em 0.25em;
    }
  }

  &.disabled {
    background-color: @color-disabled;
  }
}

@font-face {
  font-family: 'edicons';
  font-display: block;
  src: url('/themes/sky/fonts/edicons/edicons.eot?63726089');
  src: url('/themes/sky/fonts/edicons/edicons.eot?63726089#iefix') format('embedded-opentype'),
       url('/themes/sky/fonts/edicons/edicons.woff2?63726089') format('woff2'),
       url('/themes/sky/fonts/edicons/edicons.woff?63726089') format('woff'),
       url('/themes/sky/fonts/edicons/edicons.ttf?63726089') format('truetype'),
       url('/themes/sky/fonts/edicons/edicons.svg?63726089#edicons') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'edicons';
    src: url('/themes/sky/fonts/edicons/edicons.svg?1750070#edicons') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "edicons";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-music:before { content: '\e800'; } /* '' */
.icon-el:before { content: '\e801'; } /* '' */
.icon-cancel:before { content: '\e802'; } /* '' */
.icon-ok-squared:before { content: '\e803'; } /* '' */
.icon-ok-circled2:before { content: '\e804'; } /* '' */
.icon-ok-circled1:before { content: '\e805'; } /* '' */
.icon-ok:before { content: '\e806'; } /* '' */
.icon-mail:before { content: '\e807'; } /* '' */
.icon-mail-alt:before { content: '\e808'; } /* '' */
.icon-heart:before { content: '\e809'; } /* '' */
.icon-heart-empty:before { content: '\e80a'; } /* '' */
.icon-star:before { content: '\e80b'; } /* '' */
.icon-star-empty:before { content: '\e80c'; } /* '' */
.icon-star-half:before { content: '\e80d'; } /* '' */
.icon-star-half-alt:before { content: '\e80e'; } /* '' */
.icon-child:before { content: '\e80f'; } /* '' */
.icon-female:before { content: '\e810'; } /* '' */
.icon-male:before { content: '\e811'; } /* '' */
.icon-users:before { content: '\e812'; } /* '' */
.icon-user-1:before { content: '\e813'; } /* '' */
.icon-videocam:before { content: '\e814'; } /* '' */
.icon-picture:before { content: '\e815'; } /* '' */
.icon-minus-squared-alt:before { content: '\e816'; } /* '' */
.icon-minus-circled:before { content: '\e817'; } /* '' */
.icon-minus-squared:before { content: '\e818'; } /* '' */
.icon-minus:before { content: '\e819'; } /* '' */
.icon-plus-squared-alt:before { content: '\e81a'; } /* '' */
.icon-plus-squared:before { content: '\e81b'; } /* '' */
.icon-plus:before { content: '\e81c'; } /* '' */
.icon-plus-circled:before { content: '\e81d'; } /* '' */
.icon-help:before { content: '\e81e'; } /* '' */
.icon-camera:before { content: '\e81f'; } /* '' */
.icon-camera-alt:before { content: '\e820'; } /* '' */
.icon-th:before { content: '\e821'; } /* '' */
.icon-th-list:before { content: '\e822'; } /* '' */
.icon-th-large:before { content: '\e823'; } /* '' */
.icon-search:before { content: '\e824'; } /* '' */
.icon-math:before { content: '\e825'; } /* '' */
.icon-mail-squared:before { content: '\e826'; } /* '' */
.icon-help-circled:before { content: '\e827'; } /* '' */
.icon-info-circled2:before { content: '\e828'; } /* '' */
.icon-info:before { content: '\e829'; } /* '' */
.icon-home:before { content: '\e82a'; } /* '' */
.icon-link:before { content: '\e82b'; } /* '' */
.icon-unlink:before { content: '\e82c'; } /* '' */
.icon-link-ext:before { content: '\e82d'; } /* '' */
.icon-link-ext-alt:before { content: '\e82e'; } /* '' */
.icon-attach:before { content: '\e82f'; } /* '' */
.icon-lock:before { content: '\e830'; } /* '' */
.icon-lock-open:before { content: '\e831'; } /* '' */
.icon-lock-open-alt:before { content: '\e832'; } /* '' */
.icon-eye-off:before { content: '\e8d8'; } /* '' */
.icon-pin:before { content: '\e833'; } /* '' */
.icon-thumbs-up:before { content: '\e834'; } /* '' */
.icon-thumbs-down:before { content: '\e835'; } /* '' */
.icon-thumbs-up-alt:before { content: '\e836'; } /* '' */
.icon-thumbs-down-alt:before { content: '\e837'; } /* '' */
.icon-download-cloud:before { content: '\e838'; } /* '' */
.icon-upload-cloud:before { content: '\e839'; } /* '' */
.icon-plus-squared-alt-new:before { content: '\e83a'; } /* '' */
.icon-edu-book:before { content: '\e83b'; } /* '' */
.icon-workbook:before { content: '\e83c'; } /* '' */
.icon-quote-left:before { content: '\e83d'; } /* '' */
.icon-quote-right:before { content: '\e83e'; } /* '' */
.icon-child-face:before { content: '\e8d7'; } /* '' */
.icon-share-squared:before { content: '\e83f'; } /* '' */
.icon-pencil:before { content: '\e840'; } /* '' */
.icon-edit:before { content: '\e841'; } /* '' */
.icon-pencil-squared:before { content: '\e842'; } /* '' */
.icon-print:before { content: '\e843'; } /* '' */
.icon-comment:before { content: '\e844'; } /* '' */
.icon-chat:before { content: '\e845'; } /* '' */
.icon-comment-empty:before { content: '\e846'; } /* '' */
.icon-location:before { content: '\e847'; } /* '' */
.icon-attention-circled2:before { content: '\e848'; } /* '' */
.icon-attention2:before { content: '\e849'; } /* '' */
.icon-attention-alt:before { content: '\e84a'; } /* '' */
.icon-trash:before { content: '\e84b'; } /* '' */
.icon-trash-empty:before { content: '\e84c'; } /* '' */
.icon-user-plus:before { content: '\e84d'; } /* '' */
.icon-user-times:before { content: '\e84e'; } /* '' */
.icon-tag:before { content: '\e84f'; } /* '' */
.icon-tags:before { content: '\e850'; } /* '' */
.icon-menu:before { content: '\e851'; } /* '' */
.icon-cog:before { content: '\e852'; } /* '' */
.icon-cog-alt:before { content: '\e853'; } /* '' */
.icon-wrench:before { content: '\e854'; } /* '' */
.icon-sliders:before { content: '\e855'; } /* '' */
.icon-cancel-circled2:before { content: '\e856'; } /* '' */
.icon-basket:before { content: '\e857'; } /* '' */
.icon-logout:before { content: '\e858'; } /* '' */
.icon-login:before { content: '\e859'; } /* '' */
.icon-resize-full-alt:before { content: '\e85a'; } /* '' */
.icon-globe:before { content: '\e85b'; } /* '' */
.icon-esl:before { content: '\e85c'; } /* '' */
.icon-block:before { content: '\e85d'; } /* '' */
.icon-zoom-in:before { content: '\e85e'; } /* '' */
.icon-zoom-out:before { content: '\e85f'; } /* '' */
.icon-down-dir:before { content: '\e860'; } /* '' */
.icon-up-dir:before { content: '\e861'; } /* '' */
.icon-left-dir:before { content: '\e862'; } /* '' */
.icon-right-dir:before { content: '\e863'; } /* '' */
.icon-down-open:before { content: '\e864'; } /* '' */
.icon-left-open:before { content: '\e865'; } /* '' */
.icon-up-open:before { content: '\e866'; } /* '' */
.icon-right-open:before { content: '\e867'; } /* '' */
.icon-angle-left:before { content: '\e868'; } /* '' */
.icon-angle-right:before { content: '\e869'; } /* '' */
.icon-angle-up:before { content: '\e86a'; } /* '' */
.icon-angle-down:before { content: '\e86b'; } /* '' */
.icon-angle-circled-left:before { content: '\e86c'; } /* '' */
.icon-angle-circled-right:before { content: '\e86d'; } /* '' */
.icon-angle-circled-up:before { content: '\e86e'; } /* '' */
.icon-angle-circled-down:before { content: '\e86f'; } /* '' */
.icon-angle-double-left:before { content: '\e870'; } /* '' */
.icon-angle-double-up:before { content: '\e871'; } /* '' */
.icon-angle-double-right:before { content: '\e872'; } /* '' */
.icon-angle-double-down:before { content: '\e873'; } /* '' */
.icon-beaker:before { content: '\e874'; } /* '' */
.icon-bank:before { content: '\e875'; } /* '' */
.icon-graduation-cap:before { content: '\e876'; } /* '' */
.icon-cc-stripe:before { content: '\e877'; } /* '' */
.icon-down-big:before { content: '\e878'; } /* '' */
.icon-left-big:before { content: '\e879'; } /* '' */
.icon-right-big:before { content: '\e87a'; } /* '' */
.icon-up-big:before { content: '\e87b'; } /* '' */
.icon-cc-visa:before { content: '\e87c'; } /* '' */
.icon-cc-mastercard:before { content: '\e87d'; } /* '' */
.icon-cc-discover:before { content: '\e87e'; } /* '' */
.icon-cc-amex:before { content: '\e87f'; } /* '' */
.icon-down-open-1:before { content: '\e880'; } /* '' */
.icon-ccw:before { content: '\e881'; } /* '' */
.icon-arrows-cw:before { content: '\e882'; } /* '' */
.icon-cc-paypal:before { content: '\e883'; } /* '' */
.icon-android:before { content: '\e884'; } /* '' */
.icon-apple:before { content: '\e885'; } /* '' */
.icon-expand:before { content: '\e886'; } /* '' */
.icon-collapse:before { content: '\e887'; } /* '' */
.icon-expand-right:before { content: '\e888'; } /* '' */
.icon-collapse-left:before { content: '\e889'; } /* '' */
.icon-play:before { content: '\e88a'; } /* '' */
.icon-clock:before { content: '\e88b'; } /* '' */
.icon-chrome:before { content: '\e88c'; } /* '' */
.icon-stop:before { content: '\e88d'; } /* '' */
.icon-pause:before { content: '\e88e'; } /* '' */
.icon-laptop:before { content: '\e88f'; } /* '' */
.icon-desktop:before { content: '\e890'; } /* '' */
.icon-tablet:before { content: '\e891'; } /* '' */
.icon-mobile:before { content: '\e892'; } /* '' */
.icon-inbox:before { content: '\e893'; } /* '' */
.icon-worksheet:before { content: '\e894'; } /* '' */
.icon-spin:before { content: '\e895'; } /* '' */
.icon-reading:before { content: '\e896'; } /* '' */
.icon-list-bullet:before { content: '\e897'; } /* '' */
.icon-list-numbered:before { content: '\e898'; } /* '' */
.icon-science:before { content: '\e899'; } /* '' */
.icon-check:before { content: '\e89a'; } /* '' */
.icon-social-studies:before { content: '\e89b'; } /* '' */
.icon-writing:before { content: '\e89c'; } /* '' */
.icon-palette:before { content: '\e89d'; } /* '' */
.icon-share:before { content: '\e89e'; } /* '' */
.icon-sort-up:before { content: '\e89f'; } /* '' */
.icon-sort-down:before { content: '\e8a0'; } /* '' */
.icon-sort:before { content: '\e8a1'; } /* '' */
.icon-cancel-circled:before { content: '\e8a2'; } /* '' */
.icon-bell:before { content: '\e8a3'; } /* '' */
.icon-story:before { content: '\e8a4'; } /* '' */
.icon-set:before { content: '\e8a5'; } /* '' */
.icon-science-project:before { content: '\e8a6'; } /* '' */
.icon-lesson-plan:before { content: '\e8a7'; } /* '' */
.icon-up-open-1:before { content: '\e8a8'; } /* '' */
.icon-right-dir-1:before { content: '\e8a9'; } /* '' */
.icon-down-dir-1:before { content: '\e8aa'; } /* '' */
.icon-up-dir-1:before { content: '\e8ab'; } /* '' */
.icon-award:before { content: '\e8ac'; } /* '' */
.icon-activity:before { content: '\e8ad'; } /* '' */
.icon-skill-builder:before { content: '\e8ae'; } /* '' */
.icon-cc:before { content: '\e8af'; } /* '' */
.icon-floppy:before { content: '\e8b0'; } /* '' */
.icon-points:before { content: '\e8b1'; } /* '' */
.icon-collect:before { content: '\e8b2'; } /* '' */
.icon-eraser:before { content: '\e8b3'; } /* '' */
.icon-volume-off:before { content: '\e8b4'; } /* '' */
.icon-volume-low:before { content: '\e8b5'; } /* '' */
.icon-volume-medium:before { content: '\e8b6'; } /* '' */
.icon-volume-high:before { content: '\e8b7'; } /* '' */
.icon-edu-logo:before { content: '\e8b8'; } /* '' */
.icon-pointer:before { content: '\e8b9'; } /* '' */
.icon-brush:before { content: '\e8ba'; } /* '' */
.icon-resize-full:before { content: '\e8bb'; } /* '' */
.icon-resize-small:before { content: '\e8bc'; } /* '' */
.icon-left:before { content: '\e8bd'; } /* '' */
.icon-down:before { content: '\e8be'; } /* '' */
.icon-right:before { content: '\e8bf'; } /* '' */
.icon-up:before { content: '\e8c0'; } /* '' */
.icon-calendar:before { content: '\e8c1'; } /* '' */
.icon-braille:before { content: '\e8c2'; } /* '' */
.icon-diamond:before { content: '\e8c3'; } /* '' */
.icon-logo-mark:before { content: '\e8c4'; } /* '' */
.icon-user:before { content: '\e8c5'; } /* '' */
.icon-attention-circled:before { content: '\e8c6'; } /* '' */
.icon-cancel-light:before { content: '\e8c7'; } /* '' */
.icon-attention:before { content: '\e8c8'; } /* '' */
.icon-info-circled:before { content: '\e8c9'; } /* '' */
.icon-ok-circled:before { content: '\e8ca'; } /* '' */
.icon-set-new:before { content: '\e8cb'; } /* '' */
.icon-ok-new:before { content: '\e8cc'; } /* '' */
.icon-play-circled2:before { content: '\e8cd'; } /* '' */
.icon-diamond-outline:before { content: '\e8ce'; } /* '' */
.icon-guided-lessons:before { content: '\e8cf'; } /* '' */
.icon-progress:before { content: '\e8d0'; } /* '' */
.icon-bookmark:before { content: '\e8d1'; } /* '' */
.icon-bookmark-filled:before { content: '\e8d2'; } /* '' */
.icon-plus-light:before { content: '\e8d3'; } /* '' */
.icon-trash-light:before { content: '\e8d4'; } /* '' */
.icon-move-to:before { content: '\e8d5'; } /* '' */
.icon-more-vert:before { content: '\e8d6'; } /* '' */
.icon-flame:before { content: '\e8ee'; } /* '' */
.icon-guided-lesson:before { content: '\e8ef'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-gauge:before { content: '\f0e4'; } /* '' */
.icon-gamepad:before { content: '\f11b'; } /* '' */
.icon-mic:before { content: '\f130'; } /* '' */
.icon-mute:before { content: '\f131'; } /* '' */
.icon-ellipsis:before { content: '\f141'; } /* '' */
.icon-ellipsis-vert:before { content: '\f142'; } /* '' */
.icon-youtube:before { content: '\f167'; } /* '' */
.icon-windows:before { content: '\f17a'; } /* '' */
.icon-paper-plane:before { content: '\f1d8'; } /* '' */
.icon-wifi:before { content: '\f1eb'; } /* '' */
.icon-chart-line:before { content: '\f201'; } /* '' */
.icon-pinterest:before { content: '\f231'; } /* '' */
.icon-medium:before { content: '\f23a'; } /* '' */
.icon-safari:before { content: '\f267'; } /* '' */
.icon-firefox:before { content: '\f269'; } /* '' */
.icon-ie:before { content: '\f26b'; } /* '' */
.icon-monitor:before { content: '\f26c'; } /* '' */
.icon-map-pin:before { content: '\f276'; } /* '' */
.icon-edge:before { content: '\f282'; } /* '' */

// Animate the spin icon
.icon-spin {
  -moz-animation: spin 2s infinite linear;
  -o-animation: spin 2s infinite linear;
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
  display: inline-block;
  transform-origin: 51% 45%;
}
@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-webkit-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-o-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@-ms-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -moz-transform: rotate(359deg);
    -o-transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
