.related-materials-container {
  width: 100%;
  clear: both;
  margin-bottom: 20px;
  margin-top: 25px;
  overflow: hidden;
  display: flex;

  .related-materials {
    width: 100%;

    .related-materials-header {
      color: @color-text-primary;
      font-size: 32px;
      padding: 5px 0;

      @media (max-width: 480px) {
        font-size: 24px;
      }

    }

    .content-carousel {
      display: flex;
      justify-content: space-around;

      .content-container {
        position: relative;

        .arrow-nav {
          position: absolute;
          margin-top: 105px;
        }

        .content-results {
          margin: 10px 0;
          justify-content: space-around;

          .content-result.hidden-content-result {
            display: none !important;
          }
        }
      }
    }
  }
}
