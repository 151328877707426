@import (reference) "layers.less";
@import (reference) "colors.less";

.inline-dropdown {
  position: relative;
  display: inline-block;

  > .contents {
    z-index: 9999;
    position: absolute;
    top: 100%;
    left: 0;
    right: auto;
    .layer-dropdown;
    background: #fff;
    color: @color-text-primary;
    display: none;
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 200px;
    border-top: 1px solid @color-light-grayDB;

    li {
      padding: 0;
      margin: 0;
      list-style-type: none;
      display: block;

      a {
        display: block;
        padding: 8px 10px;
        border-bottom: 1px solid @color-light-grayDB;;

        &:hover {
          background: @color-light-grayED;
        }
      }
    }
  }

  &.right {
    > .contents {
      right: 0;
      left: auto;
    }
  }

  &.open {
    > .contents {
      display: block;
    }
  }
}