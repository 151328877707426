@import "fonts.less";
@import (reference) "colors.less";
@import (reference) "screen-sizes.less";

// Font Sizes
.font-size-60() {
  font-size: 60px;
  line-height: 1.25;
}
.font-size-58() {
  font-size: 58px;
  line-height: 1;
}
.font-size-50() {
  font-size: 50px;
  line-height: 1;
}
.font-size-48() {
  font-size: 48px;
  line-height: 1.05;
}
.font-size-40() {
  font-size: 40px;
  line-height: 1.125;
}
.font-size-38() {
  font-size: 38px;
  line-height: 1.125;
}
.font-size-32() {
  font-size: 32px;
  line-height: 1.25;
}
.font-size-30() {
  font-size: 30px;
  line-height: 1.275;
}
.font-size-28() {
  font-size: 28px;
  line-height: 1.45;
}
.font-size-24() {
  font-size: 24px;
  line-height: 1.25;
}
.font-size-22() {
  font-size: 22px;
  line-height: 1.25;
}
.font-size-20() {
  font-size: 20px;
  line-height: 1.25;
}
.font-size-18() {
  font-size: 18px;
  line-height: 1.11;

  @media(min-width: @screen-tablet-lg) {
    line-height: 1.22;
  }
}
.font-size-16() {
  font-size: 16px;
  line-height: 1.3;

  @media(min-width: @screen-tablet-lg) {
    line-height: 1.375;
  }
}
.font-size-14() {
  font-size: 14px;
  line-height: 1.25;

  @media(min-width: @screen-tablet-lg) {
    line-height: 1.375;
  }
}

.text-large {
  .font-size-20;
  @media(max-width: @screen-tablet) {
    .font-size-18;
  }
}
.text-medium() {
  .font-size-16;
}
.text-small {
  .font-size-14;
}

.highlight {
  .text-large;
}

.details {
  .text-small;
}

.copy-light {
  color: @color-text-light;
}

// Utility classes
.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}
.align-center {
  text-align: center;
}
.uppercase {
  text-transform: uppercase;
}
.italic {
  font-style: italic;
}



//font
.tt-norms {
  font-family: TTNorms, Arial, sans-serif;
  font-weight: normal;
}
.font-primary {
  font-family: TTNorms, Arial, sans-serif;
  font-weight: normal;
}
.font-primary-bold {
  font-family: TTNorms-Medium, Arial, sans-serif;
  font-weight: 500;
}

// Body text
body, html, button, input, select, textarea, p {
  .font-primary;
  .font-size-16;
  color: @color-gray-primary;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input[type="checkbox"], input[type="radio"] {
  .font-primary;
}

p {
  .text-medium;
  .font-primary;
  margin: 4px 0 10px;
}
a:not(img) {
  //outline:none;
  text-decoration: none;
  color: @color-interactive;
  vertical-align: baseline;
  cursor: pointer;
  //padding: 3px 5px;
  &:hover {
    text-decoration: underline;
    color: @color-blue-dark;
    //border-bottom: 1px solid;
    //border-radius: 0px;
  }
  //&:focus {
  //  color: @color-blue-dark;
  //  box-shadow: 0 0 0 2px rgba(0, 196, 201, 0.4);
  //  border-radius: 57px;
  //}
}

.no-highlight-links {
  a {
    //padding: 0px;
    //border: none;
    //border-radius: 0;
    &:hover {
      //border: none;
      text-decoration: none;
      color: @color-interactive;
    }
    //&:focus {
    //  border: none;
    //  box-shadow: none;
    //  color: @color-interactive;
    //}
  }
}
a.no-highlight-link {
  /*padding: 0px;
  border: none;
  border-radius: 0;*/
  border: none;
  &:hover {
    text-decoration: none;
    color: @color-blue-dark;
  }
  //&:focus {
  //  text-decoration: none;
  //  color: @color-blue-dark;
  //}
}

a img {
  border:none;
}
a.headline {
  font-size: 30px;
}

// Headings
h1, h2, h3, h4, .text-h1, .text-h2, .text-h3, .text-h4 {
  .font-primary-bold;
}
h5, h6, .text-h5, .text-h6 {
  .font-primary-bold;
}

h1, .text-h1 {
  .font-size-60;
  color: @color-primary;
  margin: 8px 0;

  @media(max-width: @screen-desktop) {
    .font-size-48;
  }
  @media(max-width: @screen-tablet) {
    .font-size-38;
  }
  @media(max-width: @screen-phone) {
    .font-size-32;
  }
}
h2, .text-h2 {
  .font-size-50;
  margin: 6px 0;

  @media(max-width: @screen-desktop) {
    .font-size-40;
  }
  @media(max-width: @screen-tablet) {
    .font-size-32;
  }
  @media(max-width: @screen-phone) {
    .font-size-28;
  }

}
h3, .text-h3 {
  .font-size-40;
  margin: 6px 0;

  @media(max-width: @screen-desktop) {
    .font-size-32;
  }
  @media(max-width: @screen-tablet) {
    .font-size-28;
  }
  @media(max-width: @screen-phone) {
    .font-size-24;
  }
}
h4, .text-h4, .sub-heading {
  .font-size-30;

  @media(max-width: @screen-desktop) {
    .font-size-24;
  }
  @media(max-width: @screen-tablet) {
    .font-size-22;
  }
  @media(max-width: @screen-phone) {
    .font-size-20;
  }
}
h5, .text-h5 {
  .font-size-20;

  @media(max-width: @screen-desktop) {
    .font-size-18;
  }
  @media(max-width: @screen-tablet) {
    .font-size-18;
  }
  @media(max-width: @screen-phone) {
    .font-size-18;
  }
}
h6, .text-h6 {
  .font-size-16;
  .uppercase;
  margin: 4px 0;
}
.typography-small {
  h1, .text-h1 {
    .font-size-50;
    margin: 6px 0;

    @media(max-width: @screen-desktop) {
      .font-size-40;
    }
    @media(max-width: @screen-tablet) {
      .font-size-32;
    }
    @media(max-width: @screen-phone) {
      .font-size-28;
    }
  }
  h2, .text-h2 {
    .font-size-40;
    margin: 6px 0;

    @media(max-width: @screen-desktop) {
      .font-size-32;
    }
    @media(max-width: @screen-tablet) {
      .font-size-28;
    }
    @media(max-width: @screen-phone) {
      .font-size-24;
    }
  }
  h3, .text-h3 {
    .font-size-30;

    @media(max-width: @screen-desktop) {
      .font-size-24;
    }
    @media(max-width: @screen-tablet) {
      .font-size-22;
    }
    @media(max-width: @screen-phone) {
      .font-size-20;
    }

  }
  h4, .text-h4 {

    .font-size-20;

    @media(max-width: @screen-desktop) {
      .font-size-18;
    }
    @media(max-width: @screen-tablet) {
      .font-size-18;
    }
    @media(max-width: @screen-phone) {
      .font-size-18;
    }
  }
  h5, .text-h5 {
    .font-size-16;
  }
  h6, .text-h6 {
    .font-size-14;
    .uppercase;
    margin: 4px 0;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
}

// TODO: blockquotes
// TODO: add Felt Tip Roman for script font
