.flex-container {
  display: flex;
}

.flex-wrap {
  flex-flow: row wrap;
}

.flex-vertical {
  flex-direction: column;
}

.flex-column-wrap {
  flex-flow: column wrap;
}

.flex-vertical {
  flex-direction: column;
}

.flex-left {
  justify-content: flex-start;
}

.flex-right {
  justify-content: flex-end;
}

.flex-center {
  justify-content: center;
}

.flex-top {
  align-items: flex-start;
}

.flex-middle {
  align-items: center;
}

.flex-order(@order) {
  order: @order;
}

.flex-item {
  display: inline-block;
  vertical-align: top;
  flex: 1 auto;
  box-sizing: border-box;

  &.flex-container {
    display: flex;
  }
}

.flex-item-equal {
  display: inline-block;
  vertical-align: top;
  flex: 1 0 auto;
  box-sizing: border-box;

  &.flex-container {
    display: flex;
  }
}

.flex-grid {
  .flex-container;
  .flex-wrap;
  .flex-center;

  padding: 0;

  ul& {
    margin: 0;
    text-align: center;
  }

  > * {
    list-style-type: none;
    .flex-item;
  }
}

.flex-spacing() {
  margin-left: -4px;
  margin-right: -4px;
  > * {
    padding: 4px;
    margin: 4px;
  }
}

.flex-spacing-lg() {
  margin-left: -10px;
  margin-right: -10px;
  > * {
    padding: 10px;
    margin: 10px;
  }
}
