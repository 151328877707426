@import (reference) "colors.less";

.progress-steps {
  position: relative;
  display: inline-block;
  margin-top: 10px;

  .dotted-line {
    position: absolute;
    z-index: 1;
    border-bottom: 2px dashed @color-primary;
    height: 1px;
    left: 76px;
    top: 15px;
    right: 76px;
  }

  .step {
    text-align: center;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    position: relative;
    width: 110px;
    z-index: 5;

    .number {
      display: inline-block;
      font-size: 20px;
      color: @color-primary;
      text-align: center;
      width: 32px;
      height: 32px;
      line-height: 34px;
      border: 1px solid @color-primary;
      background: @color-white;
      margin-bottom: 12px;
      border-radius: 30px;
    }
    .title {
      color: @color-primary;
      font-size: 13px;
    }

    &.current {
      cursor: default;

      .number {
        width: 37px;
        height: 37px;
        line-height: 37px;
        border: 4px solid @color-primary;
        color: @color-gray-primary;
        background: @color-primary;
        margin-top: -6px;
        margin-bottom: 6px;
        font-weight: bold;
        font-size: 26px;
      }
      .title {
        color: @color-gray-primary;
        font-weight: bold;
      }
    }
    &.completed {
      cursor: default;

      .number {
        background: @color-primary;
        color: @color-white;
      }
      .title {
        color: @color-primary;
      }
    }
  }
}
