@import (reference) "colors.less";

.arrow-nav {
  @box-shadow: 0 1px 4px rgba(0, 0, 0, 0.35);

  background-color: white;
  border-radius: 40px;
  cursor: pointer;
  font-size: 27px;
  text-align: center;
  line-height: 62px;
  height: 64px;
  width: 64px;
  box-shadow: @box-shadow;
  z-index: 10;
  transform: scale(1);
  transition: 75ms transform;

  &.left-nav {
    left: -75px;
  }

  &.right-nav {
    right: -75px;
  }

  i {
    color: @color-blue-sapphire;
    transition-delay: 75ms;
  }

  &:hover {
    animation: popUp forwards 250ms;
  }

  &:active {
    transform: scale(.9);

    i {
      color: @color-primary;
      transition: 0ms color;
    }
  }

  &.disabled {
    background-color: @color-light-grayED;
    cursor: default;
    box-shadow: none;

    &:hover {
      animation: none;
    }

    &:active {
      transform: none;
    }

    i {
      color: @color-grayC5;
    }
  }

  @keyframes popUp {
    from {
      box-shadow: @box-shadow;
      font-size: 27px;
    }
    to {
      box-shadow: @box-shadow;
      font-size: 28px;
    }
  }

  @media (max-width: 420px) {
    width: 54px;
    height: 54px;
    line-height: 52px;

    &.left-nav {
      left: -35px;
    }

    &.right-nav {
      right: -35px;
    }
  }
}
