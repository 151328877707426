@import (reference) "colors.less";
@import (reference) "screen-sizes.less";

.content-results {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @media (max-width: 1355px) {
    flex-wrap: nowrap;
  }
  @media (max-width: (@screen-desktop - 1)) {
    flex-wrap: nowrap;
  }

  @media (max-width: (@screen-tablet - 1)) {
    flex-wrap: wrap;
  }
}

.content-result {
  position: relative;
  background-color: @color-cream;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  display: inline-block;
  margin: 7px;
  width: 100%;
  height: 100%;
  min-width: 230px;
  max-width: 230px;
  transition: box-shadow 200ms;


  .content-result-link {
    display: block;
    width: 230px;
    height: 240px;

    .front-section {
      border-radius: 4px;
      box-sizing: border-box;
      width: 230px;
      height: 240px;
      left: 0;
      overflow: hidden;
      white-space: normal;
      padding: 0;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1;

      .image {
        position: relative;
        background-color: @color-white;
        border: 0;
        box-sizing: border-box;
        height: 138px;
        overflow: hidden;

        img, svg {
          border-radius: 4px 4px 0 0;
          box-sizing: border-box;
          width: 100%;
          min-width: 100%;
          height: auto;

          &.inset {
            border: 10px solid #ffffff;
            background-color: #ffffff;
          }

          &.border {
            border: 1.5px solid #ffffff;
            background-color: #ffffff;
          }
        }

        &.narrow-section {
          background-color: @color-cream;
          height: 118px;
          margin: 20px auto 0;
          width: 80%;

          &.shift-left {
            transform: translateX(-8px);
          }

          img, svg {
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
            min-height: 138px;
          }
        }
      }

      .stack {
        position: absolute;
        top: 0;
        left: 0.5px; // don't ask
        width: 100%;
        height: 100%;
        pointer-events: none;
        z-index: -1;

        .page {
          position: absolute;
          height: 118px;
          width: 80%;
          background: #ffffff;
          background-size: 100% auto;
          background-position: 50% 0;
          border-radius: 4px 4px 0 0;
          box-sizing: border-box;
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);

          &.page1 {
            margin: 23px auto 0 26px;
            z-index: 0;
          }

          &.page2 {
            margin: 27px auto 0 30px;
            z-index: -1;
          }
        }

        &.wide {
          transform: translateX(-8px);
          filter: brightness(1.25) contrast(.95);

          .page1 {
            margin: 29px auto 0 33px;
            border: 1.5px solid #ffffff;
          }

          .page2 {
            margin: 39px auto 0 43px;
            border: 1.5px solid #ffffff;
          }
        }
      }

      .content-title {
        background-color: white;
        border-bottom: 1px solid @color-light-grayED;
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
        box-sizing: border-box;
        height: 104px;
        padding: 10px 20px 0;
        position: relative;
        text-align: left;

        .title {
          color: @color-text-primary;
          font-size: 16px;
          height: 40px;
          line-height: 18px;
          display: flex;
          align-items: center;
          overflow: hidden;

          span {
            margin-top: 5px;
            max-height: 42px;
          }
        }

        .content-type {
          bottom: 16px;
          color: @color-dark-gray87;
          font-size: 14px;
          line-height: 1.375;
          left: 20px;
          position: absolute;
          text-transform: capitalize;
          display: flex;
          align-items: center;
        }

        .content-rating {
          position: absolute;
          bottom: 13px;
          right: 15px;

          .full {
            .icon-star {
              color: @color-dark-teal;
            }
          }

          &.none {
            bottom: 16px;
            font-size: 14px;
            color: @color-dark-gray87;
          }
        }
      }
    }
  }
  .view-submission-btn {
    width: 230px;
    max-height: 31px;
    text-align: center;
    border-radius: 0px 0px 15px 15px;
    border: 1px solid rgba(86, 114, 196, 1);
    background: rgba(251, 250, 250, 1);
    /* 1dp Elevation Light */
    box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.07);
    padding-top: 7px;
    padding-bottom: 7px;
    cursor: pointer;

    span {
      font-size: 14px;
      font-style: normal;
      font-weight: bold;
      color: rgba(86, 114, 196, 1);
    }
    &:hover {
      text-decoration: none;
      box-shadow: 0 1px 3px 0 rgb(0, 0, 0, 0.05);
      border: 1px solid #6483df;
    }
  }

  .background-section {
    position: absolute;
    top: 0;
    background-color: white;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
    box-sizing: border-box;
    color: @color-gray-primary;
    display: flex;
    flex-direction: column;
    white-space: normal;
    width: 230px;
    height: 200px;
    margin-top: 40px;
    opacity: 0;
    padding: 10px 20px 0;
    text-align: left;
    transform: translateY(98px);
    transition: transform 150ms, opacity 150ms;
    z-index: 2;
    pointer-events: none;

    .title {
      color: @color-text-primary;
      font-size: 16px;
      height: 40px;
      line-height: 18px;
      display: flex;
      align-items: center;
      overflow: hidden;

      span {
        margin-top: 5px;
        max-height: 42px;
      }
    }

    .description {
      font-size: 14px;
      line-height: 1.375;
      height: 80px;
      margin-bottom: 4px;
      overflow: hidden;

      &.long {
        height: 134px;
      }
    }

    .categories {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      & > div {
        display: inline-block;
        color: @color-dark-gray87;
        font-size: 14px;
        padding: 0 5px 0 0;

        &:last-child {
          padding: 0;
        }
      }
    }

    .content-type {
      bottom: 16px;
      color: @color-dark-gray87;
      font-size: 14px;
      line-height: 1.375;
      left: 20px;
      position: absolute;
      text-transform: capitalize;
      display: flex;
      align-items: center;
    }

    .content-rating {
      position: absolute;
      bottom: 13px;
      right: 15px;

      .full {
        .icon-star {
          color: @color-dark-teal;
        }
      }

      &.none {
        bottom: 16px;
        font-size: 14px;
        color: @color-dark-gray87;
      }
    }

    .cta-area {
      position: absolute;
      bottom: 44px;
      left: 20px;
      width: 190px;
      pointer-events: auto;
      padding: 8px 0;

      .btn {
        width: 100%;
      }
    }
  }

  .cta-area {
    display: inline-block;
    float: right;
    bottom: -15px;
    position: relative;
    margin-right: 10px;

    .btn {
      padding: 4px 25px;
    }
  }

  .grading-ws {
    display: inline-block;
    float: right;
    bottom: -15px;
    position: relative;
    margin-right: 10px;

    .btn {
      padding: 5px 10px;
    }
  }



  .content-type {
    .content-icon {
      position: relative;
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      line-height: 24px;
      text-align: center;
      margin: 0 1px -1px -2px;
      font-size: 14px;
      overflow: hidden;

      &.center {
        display: flex;
        align-items: center;
      }

      &.premium {
        background: @color-magenta;

        .icon-diamond {
          color: #ffffff;
        }

        i:before {
          margin: 6px 0 0 0.5px;
          width: 17px;
        }

        &.premium-teal-diamond {
          background: @color-primary;
        }
      }

      &.EL {
        background: @color-peach;
        color: @color-text-primary;
      }
    }

    .content-type-name {
      display: inline-block;
      vertical-align: bottom;
    }
  }

  &:hover {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16), 0 3px 6px 0 rgba(0, 0, 0, 0.23);
  }
  
  .content-result-link:hover {

    .content-result-link {
      text-decoration: none;
    }

  }

  .content-result-link:hover + .background-section {
    opacity: 1;
    transform: translateY(0px);
  }


  &.with-cta {
    .background-section {
      padding: 20px 20px 0;

      .description {
        display: none;
      }

      .categories {
        bottom: 72px;
      }
    }
  }


  &.ratings {
    height: 300px;
    max-height: 300px;

    .content-result-link {
      border-bottom: 1px solid @color-grayD;
    }

    .ratings-container {
      background-color: @color-white;
      padding: 10px 10px 10px 20px;
      height: 40px;

      .rate-text {
        font-size: 14px;
        color: #464646;

        &.no-rating {
          padding-top: 10px;
        }
      }
    }

    .background-section {
      box-shadow: 0 -1px 6px -1px rgba(0, 0, 0, 0.3);
    }

    .content-rating {
      display: none;
    }
  }

  &.noStars {
    .content-rating {
      display: none;
    }
  }
}

.premium-only {
  .content-result {
    /* background-color: @color-peach; */
  }
}

.content-result {
  &.collectible-content {
    .content-rating {
      right: 38px !important;
    }
  }
}

.search-result-preview {
  .flex-container {
    min-height: 350px;
  }

  .col-left-qvmodal {
    min-width: 300px;
    min-height: 420px;
    margin-right: 40px;

  }

  .col-right-qvmodal {
    min-width: 300px;
  }



  .image img {
    border: 1px solid @color-light-grayDB;
  }

  .subject, .categories {
    color: @color-dark-gray87;

  }

  .description {
    margin: 20px 0px;
  }

  .dicon {
    display: inline-block;
    height: 30px;
    width: 30px;
  }

  .preview-content-type-name {
    display: inline-block;
    position: relative;
    top: 7px;
    left: -3px;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  .title-text {
    font-size: 30px;
    line-height: 38px;
  }

}

#srp-preview-modal.modal, #srp-preview-modal.react-modal {
  .modal-content {
    width: 767px;
    left: -100px;
  }

  .icon-cancel-light {
    right: -100px;
  }
}

@media (max-width: (@screen-tablet-lg - 1)) {
  .toggle-quickview-button-desktop {
    display: none;
  }
}

@media (min-width: @screen-tablet-lg) {

  .toggle-quickview-button-desktop {
    margin-bottom: 5px;
  }

  .toggle-description-desktop {
    display: none;
  }

  #unified-search-results .content-result:hover .background-section-test {
    height: 160px;
    transform: translateY(40px);
    pointer-events: all;

    .content-type {
      bottom: 14px;
      color: @color-dark-gray87;
      font-size: 14px;
      line-height: 1.375;
      left: 20px;
      position: absolute;
      text-transform: capitalize
    }

    .content-rating {
      bottom: 11px;
    }

    .title {
      margin-bottom: 5px;
      overflow: hidden;
      line-height: initial;

      span {
        margin-top: 0;
      }
    }
  }
}

.interactive-sparkles {
  width: 20px;
  height: 20px;
  padding-right: 4px;
}
