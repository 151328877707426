@import (reference) "colors.less";

.dropdown button.dropbtn {
  border: none;
  outline: none;
  background-color: inherit;
  font-family: inherit;
  margin: 0;
  font-size: 14px;
  line-height: 17px;
}

.dropdown-content {
  display: none;
}

.dropdown-content a {
  float: none;
  padding: 0 16px;
  display: block;
  text-align: left;
}

.dropdown-content.expand {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  background-color: @color-ecru;
  min-width: 160px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1001;
  padding: 16px;

  @media (max-width: 480px) {
    padding: 10px;
    right: 1px;
    height: 80px;
  }
}
