@import (reference) "screen-sizes.less";

.page-width {
  box-sizing: border-box;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  &.extra-page-padding{
    padding-right: 100px;
  }
  @media (max-width: (@screen-xl - 1)) {
    padding-left: 25px;
    padding-right: 25px;

  }
  @media (max-width: (@screen-med - 1)) {
    padding-left: 20px;
    padding-right: 20px;
  }
  @media (max-width: (@screen-xs - 1)) {
    padding-left: 10px;
    padding-right: 10px;
  }
}
.vertical-align-text {
    >* {
        vertical-align: middle;
    }
}

.clear {
  clear: both;
}

.margin-bottom {
  margin-bottom: 30px;
}

.clearfix{
  &::after {
    content: "";
    clear: both;
    display: table;
  }
}
