.notifications {
  display: none;
}

.net-promoter-score {
  opacity: 0;
  bottom: -180px;
  position: fixed;
  left: 0;
  right: 0;
}
