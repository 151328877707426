@import "styles/normalize.less";
@import "styles/utilities.less";
@import "styles/flexbox.less";
@import "styles/typography.less";
@import "styles/icons.less";
@import "styles/buttons.less";
@import "styles/alerts.less";
@import "styles/pagination.less";
@import "styles/tabs.less";
@import "styles/breadcrumbs.less";
@import "styles/progress-steps.less";
@import "styles/tables.less";
@import "styles/search-filter.less";
@import "styles/dropdowns.less";
@import "styles/related-materials.less";
@import "styles/content-cards.less";
@import "styles/carousels.less";
@import "styles/star-ratings.less";
@import "styles/white-content-cards.less";
@import "styles/collections.less";
@import "styles/decorative-icons.less";
@import "styles/top-nav.less";
@import "styles/top-nav-summer.less";
@import "styles/forms-base.less";
@import "styles/modals-base.less";
@import "styles/notifications-base.less";
@import "styles/forms.less";
@import "styles/reflex-grid.less";

/* The footer styles come from the async stylesheet */
footer.unified {
  display: none;
}
