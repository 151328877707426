@import (reference) "colors.less";
@import (reference) "color-classes.less";
@import (reference) "typography.less";

.tabs {
  &.tabs-horizontal {
    border-bottom: 1px solid fade(@color-text-primary, 20%);
    height: 42px;
    white-space: nowrap;

    a {
      margin: 0 13px;
      display: inline-block;
      text-align: center;
      color: @color-text-primary;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      > span {
        .font-primary;
        position: relative;
        padding: 2px 8px;
        margin: 8px -4px;
        border-radius: 30px;
        display: inline-block;
        height: 22px;
        transition: 250ms color, 250ms background;

        &:after {
          position: absolute;
          content: '';
          width: 100%;
          height: 6px;
          bottom: -11px;
          left: 0;
          pointer-events: none;
          background: transparent;
          transition: 250ms background;
          border-radius: 6px;
        }
      }

      .icon-holder {
        padding-bottom: 2px;
        text-align: center;

        i {
          display: inline-block;
          font-size: 20px;
          height: 40px;
          width: 40px;
          color: @color-white;
          text-align: center;
          line-height: 40px;
          border-radius: 24px;
          background: @color-gray-primary
        }
      }

      &.active {
        > span {
          .font-primary-bold;
          color: @color-text-primary;

          &:after {
            background: @color-primary;
          }
        }

        .icon-holder {
          i {
            background: @color-teal;
          }
        }
      }

      &:hover:not(.active) {
        > span {
          background: fade(@color-interactive, 10%);
        }
      }

    }
  }
}

.tabs-content {
  > div {
    padding: 10px;
    display: none;

    &.active {
      display: block;
    }
  }
}
