@import (reference) "colors.less";

table.table {
  border-spacing: 0;
  border-collapse: collapse;
  width: 100%;

  tr {
    border-top: 1px solid @color-light-grayDB;
    border-bottom: 1px solid @color-light-grayDB;
  }

  thead th {
    color: @color-dark-gray87;
    text-transform: uppercase;
    font-size: 14px;
  }

  td, th {
    padding: 8px;
  }
}
